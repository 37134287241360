@charset "UTF-8";

.wp-block-archives {
  box-sizing: border-box
}

.wp-block-archives-dropdown label {
  display: block
}

.wp-block-avatar {
  line-height: 0
}

.wp-block-avatar,
.wp-block-avatar img {
  box-sizing: border-box
}

.wp-block-avatar.aligncenter {
  text-align: center
}

.wp-block-audio {
  box-sizing: border-box
}

.wp-block-audio figcaption {
  margin-bottom: 1em;
  margin-top: .5em
}

.wp-block-audio audio {
  min-width: 300px;
  width: 100%
}

.wp-block-button__link {
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  word-break: break-word
}

.wp-block-button__link.aligncenter {
  text-align: center
}

.wp-block-button__link.alignright {
  text-align: right
}

:where(.wp-block-button__link) {
  border-radius: 9999px;
  box-shadow: none;
  padding: calc(.667em + 2px) calc(1.333em + 2px);
  text-decoration: none
}

.wp-block-button[style*=text-decoration] .wp-block-button__link {
  text-decoration: inherit
}

.wp-block-buttons>.wp-block-button.has-custom-width {
  max-width: none
}

.wp-block-buttons>.wp-block-button.has-custom-width .wp-block-button__link {
  width: 100%
}

.wp-block-buttons>.wp-block-button.has-custom-font-size .wp-block-button__link {
  font-size: inherit
}

.wp-block-buttons>.wp-block-button.wp-block-button__width-25 {
  width: calc(25% - var(--wp--style--block-gap, .5em)*.75)
}

.wp-block-buttons>.wp-block-button.wp-block-button__width-50 {
  width: calc(50% - var(--wp--style--block-gap, .5em)*.5)
}

.wp-block-buttons>.wp-block-button.wp-block-button__width-75 {
  width: calc(75% - var(--wp--style--block-gap, .5em)*.25)
}

.wp-block-buttons>.wp-block-button.wp-block-button__width-100 {
  flex-basis: 100%;
  width: 100%
}

.wp-block-buttons.is-vertical>.wp-block-button.wp-block-button__width-25 {
  width: 25%
}

.wp-block-buttons.is-vertical>.wp-block-button.wp-block-button__width-50 {
  width: 50%
}

.wp-block-buttons.is-vertical>.wp-block-button.wp-block-button__width-75 {
  width: 75%
}

.wp-block-button.is-style-squared,
.wp-block-button__link.wp-block-button.is-style-squared {
  border-radius: 0
}

.wp-block-button.no-border-radius,
.wp-block-button__link.no-border-radius {
  border-radius: 0 !important
}

.wp-block-button .wp-block-button__link.is-style-outline,
.wp-block-button.is-style-outline>.wp-block-button__link {
  border: 2px solid;
  padding: .667em 1.333em
}

.wp-block-button .wp-block-button__link.is-style-outline:not(.has-text-color),
.wp-block-button.is-style-outline>.wp-block-button__link:not(.has-text-color) {
  color: currentColor
}

.wp-block-button .wp-block-button__link.is-style-outline:not(.has-background),
.wp-block-button.is-style-outline>.wp-block-button__link:not(.has-background) {
  background-color: transparent;
  background-image: none
}

.wp-block-button .wp-block-button__link:where(.has-border-color) {
  border-width: initial
}

.wp-block-button .wp-block-button__link:where([style*=border-top-color]) {
  border-top-width: medium
}

.wp-block-button .wp-block-button__link:where([style*=border-right-color]) {
  border-right-width: medium
}

.wp-block-button .wp-block-button__link:where([style*=border-bottom-color]) {
  border-bottom-width: medium
}

.wp-block-button .wp-block-button__link:where([style*=border-left-color]) {
  border-left-width: medium
}

.wp-block-button .wp-block-button__link:where([style*=border-style]) {
  border-width: initial
}

.wp-block-button .wp-block-button__link:where([style*=border-top-style]) {
  border-top-width: medium
}

.wp-block-button .wp-block-button__link:where([style*=border-right-style]) {
  border-right-width: medium
}

.wp-block-button .wp-block-button__link:where([style*=border-bottom-style]) {
  border-bottom-width: medium
}

.wp-block-button .wp-block-button__link:where([style*=border-left-style]) {
  border-left-width: medium
}

.wp-block-buttons.is-vertical {
  flex-direction: column
}

.wp-block-buttons.is-vertical>.wp-block-button:last-child {
  margin-bottom: 0
}

.wp-block-buttons>.wp-block-button {
  display: inline-block;
  margin: 0
}

.wp-block-buttons.is-content-justification-left {
  justify-content: flex-start
}

.wp-block-buttons.is-content-justification-left.is-vertical {
  align-items: flex-start
}

.wp-block-buttons.is-content-justification-center {
  justify-content: center
}

.wp-block-buttons.is-content-justification-center.is-vertical {
  align-items: center
}

.wp-block-buttons.is-content-justification-right {
  justify-content: flex-end
}

.wp-block-buttons.is-content-justification-right.is-vertical {
  align-items: flex-end
}

.wp-block-buttons.is-content-justification-space-between {
  justify-content: space-between
}

.wp-block-buttons.aligncenter {
  text-align: center
}

.wp-block-buttons:not(.is-content-justification-space-between, .is-content-justification-right, .is-content-justification-left, .is-content-justification-center) .wp-block-button.aligncenter {
  margin-left: auto;
  margin-right: auto;
  width: 100%
}

.wp-block-buttons[style*=text-decoration] .wp-block-button,
.wp-block-buttons[style*=text-decoration] .wp-block-button__link {
  text-decoration: inherit
}

.wp-block-buttons.has-custom-font-size .wp-block-button__link {
  font-size: inherit
}

.wp-block-button.aligncenter,
.wp-block-calendar {
  text-align: center
}

.wp-block-calendar td,
.wp-block-calendar th {
  border: 1px solid;
  padding: .25em
}

.wp-block-calendar th {
  font-weight: 400
}

.wp-block-calendar caption {
  background-color: inherit
}

.wp-block-calendar table {
  border-collapse: collapse;
  width: 100%
}

.wp-block-calendar table:where(:not(.has-text-color)) {
  color: #40464d
}

.wp-block-calendar table:where(:not(.has-text-color)) td,
.wp-block-calendar table:where(:not(.has-text-color)) th {
  border-color: #ddd
}

.wp-block-calendar table.has-background th {
  background-color: inherit
}

.wp-block-calendar table.has-text-color th {
  color: inherit
}

:where(.wp-block-calendar table:not(.has-background) th) {
  background: #ddd
}

.wp-block-categories {
  box-sizing: border-box
}

.wp-block-categories.alignleft {
  margin-right: 2em
}

.wp-block-categories.alignright {
  margin-left: 2em
}

.wp-block-categories.wp-block-categories-dropdown.aligncenter {
  text-align: center
}

.wp-block-code {
  box-sizing: border-box
}

.wp-block-code code {
  display: block;
  font-family: inherit;
  overflow-wrap: break-word;
  white-space: pre-wrap
}

.wp-block-columns {
  align-items: normal !important;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap !important
}

@media (min-width:782px) {
  .wp-block-columns {
    flex-wrap: nowrap !important
  }
}

.wp-block-columns.are-vertically-aligned-top {
  align-items: flex-start
}

.wp-block-columns.are-vertically-aligned-center {
  align-items: center
}

.wp-block-columns.are-vertically-aligned-bottom {
  align-items: flex-end
}

@media (max-width:781px) {
  .wp-block-columns:not(.is-not-stacked-on-mobile)>.wp-block-column {
    flex-basis: 100% !important
  }
}

@media (min-width:782px) {
  .wp-block-columns:not(.is-not-stacked-on-mobile)>.wp-block-column {
    flex-basis: 0;
    flex-grow: 1
  }

  .wp-block-columns:not(.is-not-stacked-on-mobile)>.wp-block-column[style*=flex-basis] {
    flex-grow: 0
  }
}

.wp-block-columns.is-not-stacked-on-mobile {
  flex-wrap: nowrap !important
}

.wp-block-columns.is-not-stacked-on-mobile>.wp-block-column {
  flex-basis: 0;
  flex-grow: 1
}

.wp-block-columns.is-not-stacked-on-mobile>.wp-block-column[style*=flex-basis] {
  flex-grow: 0
}

:where(.wp-block-columns) {
  margin-bottom: 1.75em
}

:where(.wp-block-columns.has-background) {
  padding: 1.25em 2.375em
}

.wp-block-column {
  flex-grow: 1;
  min-width: 0;
  overflow-wrap: break-word;
  word-break: break-word
}

.wp-block-column.is-vertically-aligned-top {
  align-self: flex-start
}

.wp-block-column.is-vertically-aligned-center {
  align-self: center
}

.wp-block-column.is-vertically-aligned-bottom {
  align-self: flex-end
}

.wp-block-column.is-vertically-aligned-bottom,
.wp-block-column.is-vertically-aligned-center,
.wp-block-column.is-vertically-aligned-top {
  width: 100%
}

.wp-block-post-comments {
  box-sizing: border-box
}

.wp-block-post-comments .alignleft {
  float: left
}

.wp-block-post-comments .alignright {
  float: right
}

.wp-block-post-comments .navigation:after {
  clear: both;
  content: "";
  display: table
}

.wp-block-post-comments .commentlist {
  clear: both;
  list-style: none;
  margin: 0;
  padding: 0
}

.wp-block-post-comments .commentlist .comment {
  min-height: 2.25em;
  padding-left: 3.25em
}

.wp-block-post-comments .commentlist .comment p {
  font-size: 1em;
  line-height: 1.8;
  margin: 1em 0
}

.wp-block-post-comments .commentlist .children {
  list-style: none;
  margin: 0;
  padding: 0
}

.wp-block-post-comments .comment-author {
  line-height: 1.5
}

.wp-block-post-comments .comment-author .avatar {
  border-radius: 1.5em;
  display: block;
  float: left;
  height: 2.5em;
  margin-right: .75em;
  margin-top: .5em;
  width: 2.5em
}

.wp-block-post-comments .comment-author cite {
  font-style: normal
}

.wp-block-post-comments .comment-meta {
  font-size: .875em;
  line-height: 1.5
}

.wp-block-post-comments .comment-meta b {
  font-weight: 400
}

.wp-block-post-comments .comment-meta .comment-awaiting-moderation {
  display: block;
  margin-bottom: 1em;
  margin-top: 1em
}

.wp-block-post-comments .comment-body .commentmetadata {
  font-size: .875em
}

.wp-block-post-comments .comment-form-author label,
.wp-block-post-comments .comment-form-comment label,
.wp-block-post-comments .comment-form-email label,
.wp-block-post-comments .comment-form-url label {
  display: block;
  margin-bottom: .25em
}

.wp-block-post-comments .comment-form input:not([type=submit]):not([type=checkbox]),
.wp-block-post-comments .comment-form textarea {
  box-sizing: border-box;
  display: block;
  width: 100%
}

.wp-block-post-comments .comment-form-cookies-consent {
  display: flex;
  gap: .25em
}

.wp-block-post-comments .comment-form-cookies-consent #wp-comment-cookies-consent {
  margin-top: .35em
}

.wp-block-post-comments .comment-reply-title {
  margin-bottom: 0
}

.wp-block-post-comments .comment-reply-title :where(small) {
  font-size: var(--wp--preset--font-size--medium, smaller);
  margin-left: .5em
}

.wp-block-post-comments .reply {
  font-size: .875em;
  margin-bottom: 1.4em
}

.wp-block-post-comments input:not([type=submit]),
.wp-block-post-comments textarea {
  border: 1px solid #949494;
  font-family: inherit;
  font-size: 1em
}

.wp-block-post-comments input:not([type=submit]):not([type=checkbox]),
.wp-block-post-comments textarea {
  padding: calc(.667em + 2px)
}

:where(.wp-block-post-comments input[type=submit]) {
  border: none
}

.wp-block-comments-pagination>.wp-block-comments-pagination-next,
.wp-block-comments-pagination>.wp-block-comments-pagination-numbers,
.wp-block-comments-pagination>.wp-block-comments-pagination-previous {
  margin-bottom: .5em;
  margin-right: .5em
}

.wp-block-comments-pagination>.wp-block-comments-pagination-next:last-child,
.wp-block-comments-pagination>.wp-block-comments-pagination-numbers:last-child,
.wp-block-comments-pagination>.wp-block-comments-pagination-previous:last-child {
  margin-right: 0
}

.wp-block-comments-pagination .wp-block-comments-pagination-previous-arrow {
  display: inline-block;
  margin-right: 1ch
}

.wp-block-comments-pagination .wp-block-comments-pagination-previous-arrow:not(.is-arrow-chevron) {
  transform: scaleX(1)
}

.wp-block-comments-pagination .wp-block-comments-pagination-next-arrow {
  display: inline-block;
  margin-left: 1ch
}

.wp-block-comments-pagination .wp-block-comments-pagination-next-arrow:not(.is-arrow-chevron) {
  transform: scaleX(1)
}

.wp-block-comments-pagination.aligncenter {
  justify-content: center
}

.wp-block-comment-template {
  box-sizing: border-box;
  list-style: none;
  margin-bottom: 0;
  max-width: 100%;
  padding: 0
}

.wp-block-comment-template li {
  clear: both
}

.wp-block-comment-template ol {
  list-style: none;
  margin-bottom: 0;
  max-width: 100%;
  padding-left: 2rem
}

.wp-block-comment-template.alignleft {
  float: left
}

.wp-block-comment-template.aligncenter {
  margin-left: auto;
  margin-right: auto;
  width: -moz-fit-content;
  width: fit-content
}

.wp-block-comment-template.alignright {
  float: right
}

.wp-block-cover,
.wp-block-cover-image {
  align-items: center;
  background-position: 50%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  min-height: 430px;
  overflow: hidden;
  overflow: clip;
  padding: 1em;
  position: relative
}

.wp-block-cover .has-background-dim:not([class*=-background-color]),
.wp-block-cover-image .has-background-dim:not([class*=-background-color]),
.wp-block-cover-image.has-background-dim:not([class*=-background-color]),
.wp-block-cover.has-background-dim:not([class*=-background-color]) {
  background-color: #000
}

.wp-block-cover .has-background-dim.has-background-gradient,
.wp-block-cover-image .has-background-dim.has-background-gradient {
  background-color: transparent
}

.wp-block-cover-image.has-background-dim:before,
.wp-block-cover.has-background-dim:before {
  background-color: inherit;
  content: ""
}

.wp-block-cover .wp-block-cover__background,
.wp-block-cover .wp-block-cover__gradient-background,
.wp-block-cover-image .wp-block-cover__background,
.wp-block-cover-image .wp-block-cover__gradient-background,
.wp-block-cover-image.has-background-dim:not(.has-background-gradient):before,
.wp-block-cover.has-background-dim:not(.has-background-gradient):before {
  bottom: 0;
  left: 0;
  opacity: .5;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1
}

.wp-block-cover-image.has-background-dim.has-background-dim-10 .wp-block-cover__background,
.wp-block-cover-image.has-background-dim.has-background-dim-10 .wp-block-cover__gradient-background,
.wp-block-cover-image.has-background-dim.has-background-dim-10:not(.has-background-gradient):before,
.wp-block-cover.has-background-dim.has-background-dim-10 .wp-block-cover__background,
.wp-block-cover.has-background-dim.has-background-dim-10 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-10:not(.has-background-gradient):before {
  opacity: .1
}

.wp-block-cover-image.has-background-dim.has-background-dim-20 .wp-block-cover__background,
.wp-block-cover-image.has-background-dim.has-background-dim-20 .wp-block-cover__gradient-background,
.wp-block-cover-image.has-background-dim.has-background-dim-20:not(.has-background-gradient):before,
.wp-block-cover.has-background-dim.has-background-dim-20 .wp-block-cover__background,
.wp-block-cover.has-background-dim.has-background-dim-20 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-20:not(.has-background-gradient):before {
  opacity: .2
}

.wp-block-cover-image.has-background-dim.has-background-dim-30 .wp-block-cover__background,
.wp-block-cover-image.has-background-dim.has-background-dim-30 .wp-block-cover__gradient-background,
.wp-block-cover-image.has-background-dim.has-background-dim-30:not(.has-background-gradient):before,
.wp-block-cover.has-background-dim.has-background-dim-30 .wp-block-cover__background,
.wp-block-cover.has-background-dim.has-background-dim-30 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-30:not(.has-background-gradient):before {
  opacity: .3
}

.wp-block-cover-image.has-background-dim.has-background-dim-40 .wp-block-cover__background,
.wp-block-cover-image.has-background-dim.has-background-dim-40 .wp-block-cover__gradient-background,
.wp-block-cover-image.has-background-dim.has-background-dim-40:not(.has-background-gradient):before,
.wp-block-cover.has-background-dim.has-background-dim-40 .wp-block-cover__background,
.wp-block-cover.has-background-dim.has-background-dim-40 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-40:not(.has-background-gradient):before {
  opacity: .4
}

.wp-block-cover-image.has-background-dim.has-background-dim-50 .wp-block-cover__background,
.wp-block-cover-image.has-background-dim.has-background-dim-50 .wp-block-cover__gradient-background,
.wp-block-cover-image.has-background-dim.has-background-dim-50:not(.has-background-gradient):before,
.wp-block-cover.has-background-dim.has-background-dim-50 .wp-block-cover__background,
.wp-block-cover.has-background-dim.has-background-dim-50 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-50:not(.has-background-gradient):before {
  opacity: .5
}

.wp-block-cover-image.has-background-dim.has-background-dim-60 .wp-block-cover__background,
.wp-block-cover-image.has-background-dim.has-background-dim-60 .wp-block-cover__gradient-background,
.wp-block-cover-image.has-background-dim.has-background-dim-60:not(.has-background-gradient):before,
.wp-block-cover.has-background-dim.has-background-dim-60 .wp-block-cover__background,
.wp-block-cover.has-background-dim.has-background-dim-60 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-60:not(.has-background-gradient):before {
  opacity: .6
}

.wp-block-cover-image.has-background-dim.has-background-dim-70 .wp-block-cover__background,
.wp-block-cover-image.has-background-dim.has-background-dim-70 .wp-block-cover__gradient-background,
.wp-block-cover-image.has-background-dim.has-background-dim-70:not(.has-background-gradient):before,
.wp-block-cover.has-background-dim.has-background-dim-70 .wp-block-cover__background,
.wp-block-cover.has-background-dim.has-background-dim-70 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-70:not(.has-background-gradient):before {
  opacity: .7
}

.wp-block-cover-image.has-background-dim.has-background-dim-80 .wp-block-cover__background,
.wp-block-cover-image.has-background-dim.has-background-dim-80 .wp-block-cover__gradient-background,
.wp-block-cover-image.has-background-dim.has-background-dim-80:not(.has-background-gradient):before,
.wp-block-cover.has-background-dim.has-background-dim-80 .wp-block-cover__background,
.wp-block-cover.has-background-dim.has-background-dim-80 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-80:not(.has-background-gradient):before {
  opacity: .8
}

.wp-block-cover-image.has-background-dim.has-background-dim-90 .wp-block-cover__background,
.wp-block-cover-image.has-background-dim.has-background-dim-90 .wp-block-cover__gradient-background,
.wp-block-cover-image.has-background-dim.has-background-dim-90:not(.has-background-gradient):before,
.wp-block-cover.has-background-dim.has-background-dim-90 .wp-block-cover__background,
.wp-block-cover.has-background-dim.has-background-dim-90 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-90:not(.has-background-gradient):before {
  opacity: .9
}

.wp-block-cover-image.has-background-dim.has-background-dim-100 .wp-block-cover__background,
.wp-block-cover-image.has-background-dim.has-background-dim-100 .wp-block-cover__gradient-background,
.wp-block-cover-image.has-background-dim.has-background-dim-100:not(.has-background-gradient):before,
.wp-block-cover.has-background-dim.has-background-dim-100 .wp-block-cover__background,
.wp-block-cover.has-background-dim.has-background-dim-100 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-100:not(.has-background-gradient):before {
  opacity: 1
}

.wp-block-cover .wp-block-cover__background.has-background-dim.has-background-dim-0,
.wp-block-cover .wp-block-cover__gradient-background.has-background-dim.has-background-dim-0,
.wp-block-cover-image .wp-block-cover__background.has-background-dim.has-background-dim-0,
.wp-block-cover-image .wp-block-cover__gradient-background.has-background-dim.has-background-dim-0 {
  opacity: 0
}

.wp-block-cover .wp-block-cover__background.has-background-dim.has-background-dim-10,
.wp-block-cover .wp-block-cover__gradient-background.has-background-dim.has-background-dim-10,
.wp-block-cover-image .wp-block-cover__background.has-background-dim.has-background-dim-10,
.wp-block-cover-image .wp-block-cover__gradient-background.has-background-dim.has-background-dim-10 {
  opacity: .1
}

.wp-block-cover .wp-block-cover__background.has-background-dim.has-background-dim-20,
.wp-block-cover .wp-block-cover__gradient-background.has-background-dim.has-background-dim-20,
.wp-block-cover-image .wp-block-cover__background.has-background-dim.has-background-dim-20,
.wp-block-cover-image .wp-block-cover__gradient-background.has-background-dim.has-background-dim-20 {
  opacity: .2
}

.wp-block-cover .wp-block-cover__background.has-background-dim.has-background-dim-30,
.wp-block-cover .wp-block-cover__gradient-background.has-background-dim.has-background-dim-30,
.wp-block-cover-image .wp-block-cover__background.has-background-dim.has-background-dim-30,
.wp-block-cover-image .wp-block-cover__gradient-background.has-background-dim.has-background-dim-30 {
  opacity: .3
}

.wp-block-cover .wp-block-cover__background.has-background-dim.has-background-dim-40,
.wp-block-cover .wp-block-cover__gradient-background.has-background-dim.has-background-dim-40,
.wp-block-cover-image .wp-block-cover__background.has-background-dim.has-background-dim-40,
.wp-block-cover-image .wp-block-cover__gradient-background.has-background-dim.has-background-dim-40 {
  opacity: .4
}

.wp-block-cover .wp-block-cover__background.has-background-dim.has-background-dim-50,
.wp-block-cover .wp-block-cover__gradient-background.has-background-dim.has-background-dim-50,
.wp-block-cover-image .wp-block-cover__background.has-background-dim.has-background-dim-50,
.wp-block-cover-image .wp-block-cover__gradient-background.has-background-dim.has-background-dim-50 {
  opacity: .5
}

.wp-block-cover .wp-block-cover__background.has-background-dim.has-background-dim-60,
.wp-block-cover .wp-block-cover__gradient-background.has-background-dim.has-background-dim-60,
.wp-block-cover-image .wp-block-cover__background.has-background-dim.has-background-dim-60,
.wp-block-cover-image .wp-block-cover__gradient-background.has-background-dim.has-background-dim-60 {
  opacity: .6
}

.wp-block-cover .wp-block-cover__background.has-background-dim.has-background-dim-70,
.wp-block-cover .wp-block-cover__gradient-background.has-background-dim.has-background-dim-70,
.wp-block-cover-image .wp-block-cover__background.has-background-dim.has-background-dim-70,
.wp-block-cover-image .wp-block-cover__gradient-background.has-background-dim.has-background-dim-70 {
  opacity: .7
}

.wp-block-cover .wp-block-cover__background.has-background-dim.has-background-dim-80,
.wp-block-cover .wp-block-cover__gradient-background.has-background-dim.has-background-dim-80,
.wp-block-cover-image .wp-block-cover__background.has-background-dim.has-background-dim-80,
.wp-block-cover-image .wp-block-cover__gradient-background.has-background-dim.has-background-dim-80 {
  opacity: .8
}

.wp-block-cover .wp-block-cover__background.has-background-dim.has-background-dim-90,
.wp-block-cover .wp-block-cover__gradient-background.has-background-dim.has-background-dim-90,
.wp-block-cover-image .wp-block-cover__background.has-background-dim.has-background-dim-90,
.wp-block-cover-image .wp-block-cover__gradient-background.has-background-dim.has-background-dim-90 {
  opacity: .9
}

.wp-block-cover .wp-block-cover__background.has-background-dim.has-background-dim-100,
.wp-block-cover .wp-block-cover__gradient-background.has-background-dim.has-background-dim-100,
.wp-block-cover-image .wp-block-cover__background.has-background-dim.has-background-dim-100,
.wp-block-cover-image .wp-block-cover__gradient-background.has-background-dim.has-background-dim-100 {
  opacity: 1
}

.wp-block-cover-image.alignleft,
.wp-block-cover-image.alignright,
.wp-block-cover.alignleft,
.wp-block-cover.alignright {
  max-width: 420px;
  width: 100%
}

.wp-block-cover-image:after,
.wp-block-cover:after {
  content: "";
  display: block;
  font-size: 0;
  min-height: inherit
}

@supports (position:sticky) {

  .wp-block-cover-image:after,
  .wp-block-cover:after {
    content: none
  }
}

.wp-block-cover-image.aligncenter,
.wp-block-cover-image.alignleft,
.wp-block-cover-image.alignright,
.wp-block-cover.aligncenter,
.wp-block-cover.alignleft,
.wp-block-cover.alignright {
  display: flex
}

.wp-block-cover .wp-block-cover__inner-container,
.wp-block-cover-image .wp-block-cover__inner-container {
  color: inherit;
  width: 100%;
  z-index: 1
}

.wp-block-cover h1:not(.has-text-color),
.wp-block-cover h2:not(.has-text-color),
.wp-block-cover h3:not(.has-text-color),
.wp-block-cover h4:not(.has-text-color),
.wp-block-cover h5:not(.has-text-color),
.wp-block-cover h6:not(.has-text-color),
.wp-block-cover p:not(.has-text-color),
.wp-block-cover-image h1:not(.has-text-color),
.wp-block-cover-image h2:not(.has-text-color),
.wp-block-cover-image h3:not(.has-text-color),
.wp-block-cover-image h4:not(.has-text-color),
.wp-block-cover-image h5:not(.has-text-color),
.wp-block-cover-image h6:not(.has-text-color),
.wp-block-cover-image p:not(.has-text-color) {
  color: inherit
}

.wp-block-cover-image.is-position-top-left,
.wp-block-cover.is-position-top-left {
  align-items: flex-start;
  justify-content: flex-start
}

.wp-block-cover-image.is-position-top-center,
.wp-block-cover.is-position-top-center {
  align-items: flex-start;
  justify-content: center
}

.wp-block-cover-image.is-position-top-right,
.wp-block-cover.is-position-top-right {
  align-items: flex-start;
  justify-content: flex-end
}

.wp-block-cover-image.is-position-center-left,
.wp-block-cover.is-position-center-left {
  align-items: center;
  justify-content: flex-start
}

.wp-block-cover-image.is-position-center-center,
.wp-block-cover.is-position-center-center {
  align-items: center;
  justify-content: center
}

.wp-block-cover-image.is-position-center-right,
.wp-block-cover.is-position-center-right {
  align-items: center;
  justify-content: flex-end
}

.wp-block-cover-image.is-position-bottom-left,
.wp-block-cover.is-position-bottom-left {
  align-items: flex-end;
  justify-content: flex-start
}

.wp-block-cover-image.is-position-bottom-center,
.wp-block-cover.is-position-bottom-center {
  align-items: flex-end;
  justify-content: center
}

.wp-block-cover-image.is-position-bottom-right,
.wp-block-cover.is-position-bottom-right {
  align-items: flex-end;
  justify-content: flex-end
}

.wp-block-cover-image.has-custom-content-position.has-custom-content-position .wp-block-cover__inner-container,
.wp-block-cover.has-custom-content-position.has-custom-content-position .wp-block-cover__inner-container {
  margin: 0;
  width: auto
}

.wp-block-cover .wp-block-cover__image-background,
.wp-block-cover video.wp-block-cover__video-background,
.wp-block-cover-image .wp-block-cover__image-background,
.wp-block-cover-image video.wp-block-cover__video-background {
  border: none;
  bottom: 0;
  box-shadow: none;
  height: 100%;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none;
  object-fit: cover;
  outline: none;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%
}

.wp-block-cover-image.has-parallax,
.wp-block-cover.has-parallax,
.wp-block-cover__image-background.has-parallax,
video.wp-block-cover__video-background.has-parallax {
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover
}

@supports (-webkit-touch-callout:inherit) {

  .wp-block-cover-image.has-parallax,
  .wp-block-cover.has-parallax,
  .wp-block-cover__image-background.has-parallax,
  video.wp-block-cover__video-background.has-parallax {
    background-attachment: scroll
  }
}

@media (prefers-reduced-motion:reduce) {

  .wp-block-cover-image.has-parallax,
  .wp-block-cover.has-parallax,
  .wp-block-cover__image-background.has-parallax,
  video.wp-block-cover__video-background.has-parallax {
    background-attachment: scroll
  }
}

.wp-block-cover-image.is-repeated,
.wp-block-cover.is-repeated,
.wp-block-cover__image-background.is-repeated,
video.wp-block-cover__video-background.is-repeated {
  background-repeat: repeat;
  background-size: auto
}

.wp-block-cover__image-background,
.wp-block-cover__video-background {
  z-index: 0
}

.wp-block-cover-image-text,
.wp-block-cover-image-text a,
.wp-block-cover-image-text a:active,
.wp-block-cover-image-text a:focus,
.wp-block-cover-image-text a:hover,
.wp-block-cover-text,
.wp-block-cover-text a,
.wp-block-cover-text a:active,
.wp-block-cover-text a:focus,
.wp-block-cover-text a:hover,
section.wp-block-cover-image h2,
section.wp-block-cover-image h2 a,
section.wp-block-cover-image h2 a:active,
section.wp-block-cover-image h2 a:focus,
section.wp-block-cover-image h2 a:hover {
  color: #fff
}

.wp-block-cover-image .wp-block-cover.has-left-content {
  justify-content: flex-start
}

.wp-block-cover-image .wp-block-cover.has-right-content {
  justify-content: flex-end
}

.wp-block-cover-image.has-left-content .wp-block-cover-image-text,
.wp-block-cover.has-left-content .wp-block-cover-text,
section.wp-block-cover-image.has-left-content>h2 {
  margin-left: 0;
  text-align: left
}

.wp-block-cover-image.has-right-content .wp-block-cover-image-text,
.wp-block-cover.has-right-content .wp-block-cover-text,
section.wp-block-cover-image.has-right-content>h2 {
  margin-right: 0;
  text-align: right
}

.wp-block-cover .wp-block-cover-text,
.wp-block-cover-image .wp-block-cover-image-text,
section.wp-block-cover-image>h2 {
  font-size: 2em;
  line-height: 1.25;
  margin-bottom: 0;
  max-width: 840px;
  padding: .44em;
  text-align: center;
  z-index: 1
}

:where(.wp-block-cover-image:not(.has-text-color)),
:where(.wp-block-cover:not(.has-text-color)) {
  color: #fff
}

:where(.wp-block-cover-image.is-light:not(.has-text-color)),
:where(.wp-block-cover.is-light:not(.has-text-color)) {
  color: #000
}

.wp-block-details {
  box-sizing: border-box;
  overflow: hidden
}

.wp-block-details summary {
  cursor: pointer
}

.wp-block-details>:not(summary) {
  margin-block-end: 0;
  margin-block-start: var(--wp--style--block-gap)
}

.wp-block-details>:last-child {
  margin-bottom: 0
}

.wp-block-embed.alignleft,
.wp-block-embed.alignright,
.wp-block[data-align=left]>[data-type="core/embed"],
.wp-block[data-align=right]>[data-type="core/embed"] {
  max-width: 360px;
  width: 100%
}

.wp-block-embed.alignleft .wp-block-embed__wrapper,
.wp-block-embed.alignright .wp-block-embed__wrapper,
.wp-block[data-align=left]>[data-type="core/embed"] .wp-block-embed__wrapper,
.wp-block[data-align=right]>[data-type="core/embed"] .wp-block-embed__wrapper {
  min-width: 280px
}

.wp-block-cover .wp-block-embed {
  min-height: 240px;
  min-width: 320px
}

.wp-block-embed {
  overflow-wrap: break-word
}

.wp-block-embed figcaption {
  margin-bottom: 1em;
  margin-top: .5em
}

.wp-block-embed iframe {
  max-width: 100%
}

.wp-block-embed__wrapper {
  position: relative
}

.wp-embed-responsive .wp-has-aspect-ratio .wp-block-embed__wrapper:before {
  content: "";
  display: block;
  padding-top: 50%
}

.wp-embed-responsive .wp-has-aspect-ratio iframe {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%
}

.wp-embed-responsive .wp-embed-aspect-21-9 .wp-block-embed__wrapper:before {
  padding-top: 42.85%
}

.wp-embed-responsive .wp-embed-aspect-18-9 .wp-block-embed__wrapper:before {
  padding-top: 50%
}

.wp-embed-responsive .wp-embed-aspect-16-9 .wp-block-embed__wrapper:before {
  padding-top: 56.25%
}

.wp-embed-responsive .wp-embed-aspect-4-3 .wp-block-embed__wrapper:before {
  padding-top: 75%
}

.wp-embed-responsive .wp-embed-aspect-1-1 .wp-block-embed__wrapper:before {
  padding-top: 100%
}

.wp-embed-responsive .wp-embed-aspect-9-16 .wp-block-embed__wrapper:before {
  padding-top: 177.77%
}

.wp-embed-responsive .wp-embed-aspect-1-2 .wp-block-embed__wrapper:before {
  padding-top: 200%
}

.wp-block-file:not(.wp-element-button) {
  font-size: .8em
}

.wp-block-file.aligncenter {
  text-align: center
}

.wp-block-file.alignright {
  text-align: right
}

.wp-block-file *+.wp-block-file__button {
  margin-left: .75em
}

:where(.wp-block-file) {
  margin-bottom: 1.5em
}

.wp-block-file__embed {
  margin-bottom: 1em
}

:where(.wp-block-file__button) {
  border-radius: 2em;
  display: inline-block;
  padding: .5em 1em
}

:where(.wp-block-file__button):is(a):active,
:where(.wp-block-file__button):is(a):focus,
:where(.wp-block-file__button):is(a):hover,
:where(.wp-block-file__button):is(a):visited {
  box-shadow: none;
  color: #fff;
  opacity: .85;
  text-decoration: none
}

.blocks-gallery-grid:not(.has-nested-images),
.wp-block-gallery:not(.has-nested-images) {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0;
  padding: 0
}

.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-image,
.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-item,
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-image,
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-item {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  margin: 0 1em 1em 0;
  position: relative;
  width: calc(50% - 1em)
}

.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-image:nth-of-type(2n),
.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-item:nth-of-type(2n),
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-image:nth-of-type(2n),
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-item:nth-of-type(2n) {
  margin-right: 0
}

.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-image figure,
.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-item figure,
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-image figure,
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-item figure {
  align-items: flex-end;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  margin: 0
}

.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-image img,
.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-item img,
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-image img,
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-item img {
  display: block;
  height: auto;
  max-width: 100%;
  width: auto
}

.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-image figcaption,
.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-item figcaption,
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-image figcaption,
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-item figcaption {
  background: linear-gradient(0deg, rgba(0, 0, 0, .7), rgba(0, 0, 0, .3) 70%, transparent);
  bottom: 0;
  box-sizing: border-box;
  color: #fff;
  font-size: .8em;
  margin: 0;
  max-height: 100%;
  overflow: auto;
  padding: 3em .77em .7em;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 2
}

.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-image figcaption img,
.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-item figcaption img,
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-image figcaption img,
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-item figcaption img {
  display: inline
}

.blocks-gallery-grid:not(.has-nested-images) figcaption,
.wp-block-gallery:not(.has-nested-images) figcaption {
  flex-grow: 1
}

.blocks-gallery-grid:not(.has-nested-images).is-cropped .blocks-gallery-image a,
.blocks-gallery-grid:not(.has-nested-images).is-cropped .blocks-gallery-image img,
.blocks-gallery-grid:not(.has-nested-images).is-cropped .blocks-gallery-item a,
.blocks-gallery-grid:not(.has-nested-images).is-cropped .blocks-gallery-item img,
.wp-block-gallery:not(.has-nested-images).is-cropped .blocks-gallery-image a,
.wp-block-gallery:not(.has-nested-images).is-cropped .blocks-gallery-image img,
.wp-block-gallery:not(.has-nested-images).is-cropped .blocks-gallery-item a,
.wp-block-gallery:not(.has-nested-images).is-cropped .blocks-gallery-item img {
  flex: 1;
  height: 100%;
  object-fit: cover;
  width: 100%
}

.blocks-gallery-grid:not(.has-nested-images).columns-1 .blocks-gallery-image,
.blocks-gallery-grid:not(.has-nested-images).columns-1 .blocks-gallery-item,
.wp-block-gallery:not(.has-nested-images).columns-1 .blocks-gallery-image,
.wp-block-gallery:not(.has-nested-images).columns-1 .blocks-gallery-item {
  margin-right: 0;
  width: 100%
}

@media (min-width:600px) {

  .blocks-gallery-grid:not(.has-nested-images).columns-3 .blocks-gallery-image,
  .blocks-gallery-grid:not(.has-nested-images).columns-3 .blocks-gallery-item,
  .wp-block-gallery:not(.has-nested-images).columns-3 .blocks-gallery-image,
  .wp-block-gallery:not(.has-nested-images).columns-3 .blocks-gallery-item {
    margin-right: 1em;
    width: calc(33.33333% - .66667em)
  }

  .blocks-gallery-grid:not(.has-nested-images).columns-4 .blocks-gallery-image,
  .blocks-gallery-grid:not(.has-nested-images).columns-4 .blocks-gallery-item,
  .wp-block-gallery:not(.has-nested-images).columns-4 .blocks-gallery-image,
  .wp-block-gallery:not(.has-nested-images).columns-4 .blocks-gallery-item {
    margin-right: 1em;
    width: calc(25% - .75em)
  }

  .blocks-gallery-grid:not(.has-nested-images).columns-5 .blocks-gallery-image,
  .blocks-gallery-grid:not(.has-nested-images).columns-5 .blocks-gallery-item,
  .wp-block-gallery:not(.has-nested-images).columns-5 .blocks-gallery-image,
  .wp-block-gallery:not(.has-nested-images).columns-5 .blocks-gallery-item {
    margin-right: 1em;
    width: calc(20% - .8em)
  }

  .blocks-gallery-grid:not(.has-nested-images).columns-6 .blocks-gallery-image,
  .blocks-gallery-grid:not(.has-nested-images).columns-6 .blocks-gallery-item,
  .wp-block-gallery:not(.has-nested-images).columns-6 .blocks-gallery-image,
  .wp-block-gallery:not(.has-nested-images).columns-6 .blocks-gallery-item {
    margin-right: 1em;
    width: calc(16.66667% - .83333em)
  }

  .blocks-gallery-grid:not(.has-nested-images).columns-7 .blocks-gallery-image,
  .blocks-gallery-grid:not(.has-nested-images).columns-7 .blocks-gallery-item,
  .wp-block-gallery:not(.has-nested-images).columns-7 .blocks-gallery-image,
  .wp-block-gallery:not(.has-nested-images).columns-7 .blocks-gallery-item {
    margin-right: 1em;
    width: calc(14.28571% - .85714em)
  }

  .blocks-gallery-grid:not(.has-nested-images).columns-8 .blocks-gallery-image,
  .blocks-gallery-grid:not(.has-nested-images).columns-8 .blocks-gallery-item,
  .wp-block-gallery:not(.has-nested-images).columns-8 .blocks-gallery-image,
  .wp-block-gallery:not(.has-nested-images).columns-8 .blocks-gallery-item {
    margin-right: 1em;
    width: calc(12.5% - .875em)
  }

  .blocks-gallery-grid:not(.has-nested-images).columns-1 .blocks-gallery-image:nth-of-type(1n),
  .blocks-gallery-grid:not(.has-nested-images).columns-1 .blocks-gallery-item:nth-of-type(1n),
  .blocks-gallery-grid:not(.has-nested-images).columns-2 .blocks-gallery-image:nth-of-type(2n),
  .blocks-gallery-grid:not(.has-nested-images).columns-2 .blocks-gallery-item:nth-of-type(2n),
  .blocks-gallery-grid:not(.has-nested-images).columns-3 .blocks-gallery-image:nth-of-type(3n),
  .blocks-gallery-grid:not(.has-nested-images).columns-3 .blocks-gallery-item:nth-of-type(3n),
  .blocks-gallery-grid:not(.has-nested-images).columns-4 .blocks-gallery-image:nth-of-type(4n),
  .blocks-gallery-grid:not(.has-nested-images).columns-4 .blocks-gallery-item:nth-of-type(4n),
  .blocks-gallery-grid:not(.has-nested-images).columns-5 .blocks-gallery-image:nth-of-type(5n),
  .blocks-gallery-grid:not(.has-nested-images).columns-5 .blocks-gallery-item:nth-of-type(5n),
  .blocks-gallery-grid:not(.has-nested-images).columns-6 .blocks-gallery-image:nth-of-type(6n),
  .blocks-gallery-grid:not(.has-nested-images).columns-6 .blocks-gallery-item:nth-of-type(6n),
  .blocks-gallery-grid:not(.has-nested-images).columns-7 .blocks-gallery-image:nth-of-type(7n),
  .blocks-gallery-grid:not(.has-nested-images).columns-7 .blocks-gallery-item:nth-of-type(7n),
  .blocks-gallery-grid:not(.has-nested-images).columns-8 .blocks-gallery-image:nth-of-type(8n),
  .blocks-gallery-grid:not(.has-nested-images).columns-8 .blocks-gallery-item:nth-of-type(8n),
  .wp-block-gallery:not(.has-nested-images).columns-1 .blocks-gallery-image:nth-of-type(1n),
  .wp-block-gallery:not(.has-nested-images).columns-1 .blocks-gallery-item:nth-of-type(1n),
  .wp-block-gallery:not(.has-nested-images).columns-2 .blocks-gallery-image:nth-of-type(2n),
  .wp-block-gallery:not(.has-nested-images).columns-2 .blocks-gallery-item:nth-of-type(2n),
  .wp-block-gallery:not(.has-nested-images).columns-3 .blocks-gallery-image:nth-of-type(3n),
  .wp-block-gallery:not(.has-nested-images).columns-3 .blocks-gallery-item:nth-of-type(3n),
  .wp-block-gallery:not(.has-nested-images).columns-4 .blocks-gallery-image:nth-of-type(4n),
  .wp-block-gallery:not(.has-nested-images).columns-4 .blocks-gallery-item:nth-of-type(4n),
  .wp-block-gallery:not(.has-nested-images).columns-5 .blocks-gallery-image:nth-of-type(5n),
  .wp-block-gallery:not(.has-nested-images).columns-5 .blocks-gallery-item:nth-of-type(5n),
  .wp-block-gallery:not(.has-nested-images).columns-6 .blocks-gallery-image:nth-of-type(6n),
  .wp-block-gallery:not(.has-nested-images).columns-6 .blocks-gallery-item:nth-of-type(6n),
  .wp-block-gallery:not(.has-nested-images).columns-7 .blocks-gallery-image:nth-of-type(7n),
  .wp-block-gallery:not(.has-nested-images).columns-7 .blocks-gallery-item:nth-of-type(7n),
  .wp-block-gallery:not(.has-nested-images).columns-8 .blocks-gallery-image:nth-of-type(8n),
  .wp-block-gallery:not(.has-nested-images).columns-8 .blocks-gallery-item:nth-of-type(8n) {
    margin-right: 0
  }
}

.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-image:last-child,
.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-item:last-child,
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-image:last-child,
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-item:last-child {
  margin-right: 0
}

.blocks-gallery-grid:not(.has-nested-images).alignleft,
.blocks-gallery-grid:not(.has-nested-images).alignright,
.wp-block-gallery:not(.has-nested-images).alignleft,
.wp-block-gallery:not(.has-nested-images).alignright {
  max-width: 420px;
  width: 100%
}

.blocks-gallery-grid:not(.has-nested-images).aligncenter .blocks-gallery-item figure,
.wp-block-gallery:not(.has-nested-images).aligncenter .blocks-gallery-item figure {
  justify-content: center
}

.wp-block-gallery:not(.is-cropped) .blocks-gallery-item {
  align-self: flex-start
}

figure.wp-block-gallery.has-nested-images {
  align-items: normal
}

.wp-block-gallery.has-nested-images figure.wp-block-image:not(#individual-image) {
  margin: 0;
  width: calc(50% - var(--wp--style--unstable-gallery-gap, 16px)/2)
}

.wp-block-gallery.has-nested-images figure.wp-block-image {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  max-width: 100%;
  position: relative
}

.wp-block-gallery.has-nested-images figure.wp-block-image>a,
.wp-block-gallery.has-nested-images figure.wp-block-image>div {
  flex-direction: column;
  flex-grow: 1;
  margin: 0
}

.wp-block-gallery.has-nested-images figure.wp-block-image img {
  display: block;
  height: auto;
  max-width: 100% !important;
  width: auto
}

.wp-block-gallery.has-nested-images figure.wp-block-image figcaption {
  background: linear-gradient(0deg, rgba(0, 0, 0, .7), rgba(0, 0, 0, .3) 70%, transparent);
  bottom: 0;
  box-sizing: border-box;
  color: #fff;
  font-size: 13px;
  left: 0;
  margin-bottom: 0;
  max-height: 60%;
  overflow: auto;
  padding: 0 8px 8px;
  position: absolute;
  text-align: center;
  width: 100%
}

.wp-block-gallery.has-nested-images figure.wp-block-image figcaption img {
  display: inline
}

.wp-block-gallery.has-nested-images figure.wp-block-image figcaption a {
  color: inherit
}

.wp-block-gallery.has-nested-images figure.wp-block-image.has-custom-border img {
  box-sizing: border-box
}

.wp-block-gallery.has-nested-images figure.wp-block-image.has-custom-border>a,
.wp-block-gallery.has-nested-images figure.wp-block-image.has-custom-border>div,
.wp-block-gallery.has-nested-images figure.wp-block-image.is-style-rounded>a,
.wp-block-gallery.has-nested-images figure.wp-block-image.is-style-rounded>div {
  flex: 1 1 auto
}

.wp-block-gallery.has-nested-images figure.wp-block-image.has-custom-border figcaption,
.wp-block-gallery.has-nested-images figure.wp-block-image.is-style-rounded figcaption {
  background: none;
  color: inherit;
  flex: initial;
  margin: 0;
  padding: 10px 10px 9px;
  position: relative
}

.wp-block-gallery.has-nested-images figcaption {
  flex-basis: 100%;
  flex-grow: 1;
  text-align: center
}

.wp-block-gallery.has-nested-images:not(.is-cropped) figure.wp-block-image:not(#individual-image) {
  margin-bottom: auto;
  margin-top: 0
}

.wp-block-gallery.has-nested-images.is-cropped figure.wp-block-image:not(#individual-image) {
  align-self: inherit
}

.wp-block-gallery.has-nested-images.is-cropped figure.wp-block-image:not(#individual-image)>a,
.wp-block-gallery.has-nested-images.is-cropped figure.wp-block-image:not(#individual-image)>div:not(.components-drop-zone) {
  display: flex
}

.wp-block-gallery.has-nested-images.is-cropped figure.wp-block-image:not(#individual-image) a,
.wp-block-gallery.has-nested-images.is-cropped figure.wp-block-image:not(#individual-image) img {
  flex: 1 0 0%;
  height: 100%;
  object-fit: cover;
  width: 100%
}

.wp-block-gallery.has-nested-images.columns-1 figure.wp-block-image:not(#individual-image) {
  width: 100%
}

@media (min-width:600px) {
  .wp-block-gallery.has-nested-images.columns-3 figure.wp-block-image:not(#individual-image) {
    width: calc(33.33333% - var(--wp--style--unstable-gallery-gap, 16px)*.66667)
  }

  .wp-block-gallery.has-nested-images.columns-4 figure.wp-block-image:not(#individual-image) {
    width: calc(25% - var(--wp--style--unstable-gallery-gap, 16px)*.75)
  }

  .wp-block-gallery.has-nested-images.columns-5 figure.wp-block-image:not(#individual-image) {
    width: calc(20% - var(--wp--style--unstable-gallery-gap, 16px)*.8)
  }

  .wp-block-gallery.has-nested-images.columns-6 figure.wp-block-image:not(#individual-image) {
    width: calc(16.66667% - var(--wp--style--unstable-gallery-gap, 16px)*.83333)
  }

  .wp-block-gallery.has-nested-images.columns-7 figure.wp-block-image:not(#individual-image) {
    width: calc(14.28571% - var(--wp--style--unstable-gallery-gap, 16px)*.85714)
  }

  .wp-block-gallery.has-nested-images.columns-8 figure.wp-block-image:not(#individual-image) {
    width: calc(12.5% - var(--wp--style--unstable-gallery-gap, 16px)*.875)
  }

  .wp-block-gallery.has-nested-images.columns-default figure.wp-block-image:not(#individual-image) {
    width: calc(33.33% - var(--wp--style--unstable-gallery-gap, 16px)*.66667)
  }

  .wp-block-gallery.has-nested-images.columns-default figure.wp-block-image:not(#individual-image):first-child:nth-last-child(2),
  .wp-block-gallery.has-nested-images.columns-default figure.wp-block-image:not(#individual-image):first-child:nth-last-child(2)~figure.wp-block-image:not(#individual-image) {
    width: calc(50% - var(--wp--style--unstable-gallery-gap, 16px)*.5)
  }

  .wp-block-gallery.has-nested-images.columns-default figure.wp-block-image:not(#individual-image):first-child:last-child {
    width: 100%
  }
}

.wp-block-gallery.has-nested-images.alignleft,
.wp-block-gallery.has-nested-images.alignright {
  max-width: 420px;
  width: 100%
}

.wp-block-gallery.has-nested-images.aligncenter {
  justify-content: center
}

.wp-block-group {
  box-sizing: border-box
}

h1.has-background,
h2.has-background,
h3.has-background,
h4.has-background,
h5.has-background,
h6.has-background {
  padding: 1.25em 2.375em
}

.wp-block-image img {
  box-sizing: border-box;
  height: auto;
  max-width: 100%;
  vertical-align: bottom
}

.wp-block-image[style*=border-radius] img,
.wp-block-image[style*=border-radius]>a {
  border-radius: inherit
}

.wp-block-image.has-custom-border img {
  box-sizing: border-box
}

.wp-block-image.aligncenter {
  text-align: center
}

.wp-block-image.alignfull img,
.wp-block-image.alignwide img {
  height: auto;
  width: 100%
}

.wp-block-image .aligncenter,
.wp-block-image .alignleft,
.wp-block-image .alignright,
.wp-block-image.aligncenter,
.wp-block-image.alignleft,
.wp-block-image.alignright {
  display: table
}

.wp-block-image .aligncenter>figcaption,
.wp-block-image .alignleft>figcaption,
.wp-block-image .alignright>figcaption,
.wp-block-image.aligncenter>figcaption,
.wp-block-image.alignleft>figcaption,
.wp-block-image.alignright>figcaption {
  caption-side: bottom;
  display: table-caption
}

.wp-block-image .alignleft {
  float: left;
  margin: .5em 1em .5em 0
}

.wp-block-image .alignright {
  float: right;
  margin: .5em 0 .5em 1em
}

.wp-block-image .aligncenter {
  margin-left: auto;
  margin-right: auto
}

.wp-block-image figcaption {
  margin-bottom: 1em;
  margin-top: .5em
}

.wp-block-image .is-style-rounded img,
.wp-block-image.is-style-circle-mask img,
.wp-block-image.is-style-rounded img {
  border-radius: 9999px
}

@supports ((-webkit-mask-image:none) or (mask-image:none)) or (-webkit-mask-image:none) {
  .wp-block-image.is-style-circle-mask img {
    border-radius: 0;
    -webkit-mask-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="50"/></svg>');
    mask-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="50"/></svg>');
    mask-mode: alpha;
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain
  }
}

.wp-block-image :where(.has-border-color) {
  border-style: solid
}

.wp-block-image :where([style*=border-top-color]) {
  border-top-style: solid
}

.wp-block-image :where([style*=border-right-color]) {
  border-right-style: solid
}

.wp-block-image :where([style*=border-bottom-color]) {
  border-bottom-style: solid
}

.wp-block-image :where([style*=border-left-color]) {
  border-left-style: solid
}

.wp-block-image :where([style*=border-width]) {
  border-style: solid
}

.wp-block-image :where([style*=border-top-width]) {
  border-top-style: solid
}

.wp-block-image :where([style*=border-right-width]) {
  border-right-style: solid
}

.wp-block-image :where([style*=border-bottom-width]) {
  border-bottom-style: solid
}

.wp-block-image :where([style*=border-left-width]) {
  border-left-style: solid
}

.wp-block-image figure {
  margin: 0
}

.wp-lightbox-container .img-container {
  position: relative
}

.wp-lightbox-container button {
  background: none;
  border: none;
  cursor: zoom-in;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 100
}

.wp-lightbox-container button:focus-visible {
  outline: 5px auto #212121;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: 5px
}

.wp-lightbox-overlay {
  height: 100vh;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  visibility: hidden;
  width: 100vw;
  z-index: 100000
}

.wp-lightbox-overlay .close-button {
  cursor: pointer;
  padding: 0;
  position: absolute;
  right: 12.5px;
  top: 12.5px;
  z-index: 5000000
}

.wp-lightbox-overlay .wp-block-image {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 3000000
}

.wp-lightbox-overlay .wp-block-image figcaption {
  display: none
}

.wp-lightbox-overlay .wp-block-image img {
  max-height: 100%;
  max-width: 100%;
  width: auto
}

.wp-lightbox-overlay button {
  background: none;
  border: none
}

.wp-lightbox-overlay .scrim {
  background-color: #fff;
  height: 100%;
  opacity: .9;
  position: absolute;
  width: 100%;
  z-index: 2000000
}

.wp-lightbox-overlay.fade.active {
  animation: turn-on-visibility .25s both;
  visibility: visible
}

.wp-lightbox-overlay.fade.active img {
  animation: turn-on-visibility .3s both
}

.wp-lightbox-overlay.fade.hideanimationenabled:not(.active) {
  animation: turn-off-visibility .3s both
}

.wp-lightbox-overlay.fade.hideanimationenabled:not(.active) img {
  animation: turn-off-visibility .25s both
}

.wp-lightbox-overlay.zoom img {
  height: var(--lightbox-image-max-height);
  position: absolute;
  transform-origin: top left;
  width: var(--lightbox-image-max-width)
}

.wp-lightbox-overlay.zoom.active {
  opacity: 1;
  visibility: visible
}

.wp-lightbox-overlay.zoom.active .wp-block-image img {
  animation: lightbox-zoom-in .4s forwards
}

@media (prefers-reduced-motion) {
  .wp-lightbox-overlay.zoom.active .wp-block-image img {
    animation: turn-on-visibility .4s both
  }
}

.wp-lightbox-overlay.zoom.active .scrim {
  animation: turn-on-visibility .4s forwards
}

.wp-lightbox-overlay.zoom.hideanimationenabled:not(.active) .wp-block-image img {
  animation: lightbox-zoom-out .4s forwards
}

@media (prefers-reduced-motion) {
  .wp-lightbox-overlay.zoom.hideanimationenabled:not(.active) .wp-block-image img {
    animation: turn-off-visibility .4s both
  }
}

.wp-lightbox-overlay.zoom.hideanimationenabled:not(.active) .scrim {
  animation: turn-off-visibility .4s forwards
}

html.has-lightbox-open {
  overflow: hidden
}

@keyframes turn-on-visibility {
  0% {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

@keyframes turn-off-visibility {
  0% {
    opacity: 1;
    visibility: visible
  }

  99% {
    opacity: 0;
    visibility: visible
  }

  to {
    opacity: 0;
    visibility: hidden
  }
}

@keyframes lightbox-zoom-in {
  0% {
    left: var(--lightbox-initial-left-position);
    top: var(--lightbox-initial-top-position);
    transform: scale(var(--lightbox-scale-width), var(--lightbox-scale-height))
  }

  to {
    left: var(--lightbox-target-left-position);
    top: var(--lightbox-target-top-position);
    transform: scale(1)
  }
}

@keyframes lightbox-zoom-out {
  0% {
    left: var(--lightbox-target-left-position);
    top: var(--lightbox-target-top-position);
    transform: scale(1);
    visibility: visible
  }

  99% {
    visibility: visible
  }

  to {
    left: var(--lightbox-initial-left-position);
    top: var(--lightbox-initial-top-position);
    transform: scale(var(--lightbox-scale-width), var(--lightbox-scale-height))
  }
}

ol.wp-block-latest-comments {
  box-sizing: border-box;
  margin-left: 0
}

:where(.wp-block-latest-comments:not([style*=line-height] .wp-block-latest-comments__comment)) {
  line-height: 1.1
}

:where(.wp-block-latest-comments:not([style*=line-height] .wp-block-latest-comments__comment-excerpt p)) {
  line-height: 1.8
}

.has-dates :where(.wp-block-latest-comments:not([style*=line-height])),
.has-excerpts :where(.wp-block-latest-comments:not([style*=line-height])) {
  line-height: 1.5
}

.wp-block-latest-comments .wp-block-latest-comments {
  padding-left: 0
}

.wp-block-latest-comments__comment {
  list-style: none;
  margin-bottom: 1em
}

.has-avatars .wp-block-latest-comments__comment {
  list-style: none;
  min-height: 2.25em
}

.has-avatars .wp-block-latest-comments__comment .wp-block-latest-comments__comment-excerpt,
.has-avatars .wp-block-latest-comments__comment .wp-block-latest-comments__comment-meta {
  margin-left: 3.25em
}

.wp-block-latest-comments__comment-excerpt p {
  font-size: .875em;
  margin: .36em 0 1.4em
}

.wp-block-latest-comments__comment-date {
  display: block;
  font-size: .75em
}

.wp-block-latest-comments .avatar,
.wp-block-latest-comments__comment-avatar {
  border-radius: 1.5em;
  display: block;
  float: left;
  height: 2.5em;
  margin-right: .75em;
  width: 2.5em
}

.wp-block-latest-comments[class*=-font-size] a,
.wp-block-latest-comments[style*=font-size] a {
  font-size: inherit
}

.wp-block-latest-posts {
  box-sizing: border-box
}

.wp-block-latest-posts.alignleft {
  margin-right: 2em
}

.wp-block-latest-posts.alignright {
  margin-left: 2em
}

.wp-block-latest-posts.wp-block-latest-posts__list {
  list-style: none;
  padding-left: 0
}

.wp-block-latest-posts.wp-block-latest-posts__list li {
  clear: both
}

.wp-block-latest-posts.is-grid {
  display: flex;
  flex-wrap: wrap;
  padding: 0
}

.wp-block-latest-posts.is-grid li {
  margin: 0 1.25em 1.25em 0;
  width: 100%
}

@media (min-width:600px) {
  .wp-block-latest-posts.columns-2 li {
    width: calc(50% - .625em)
  }

  .wp-block-latest-posts.columns-2 li:nth-child(2n) {
    margin-right: 0
  }

  .wp-block-latest-posts.columns-3 li {
    width: calc(33.33333% - .83333em)
  }

  .wp-block-latest-posts.columns-3 li:nth-child(3n) {
    margin-right: 0
  }

  .wp-block-latest-posts.columns-4 li {
    width: calc(25% - .9375em)
  }

  .wp-block-latest-posts.columns-4 li:nth-child(4n) {
    margin-right: 0
  }

  .wp-block-latest-posts.columns-5 li {
    width: calc(20% - 1em)
  }

  .wp-block-latest-posts.columns-5 li:nth-child(5n) {
    margin-right: 0
  }

  .wp-block-latest-posts.columns-6 li {
    width: calc(16.66667% - 1.04167em)
  }

  .wp-block-latest-posts.columns-6 li:nth-child(6n) {
    margin-right: 0
  }
}

.wp-block-latest-posts__post-author,
.wp-block-latest-posts__post-date {
  display: block;
  font-size: .8125em
}

.wp-block-latest-posts__post-excerpt {
  margin-bottom: 1em;
  margin-top: .5em
}

.wp-block-latest-posts__featured-image a {
  display: inline-block
}

.wp-block-latest-posts__featured-image img {
  height: auto;
  max-width: 100%;
  width: auto
}

.wp-block-latest-posts__featured-image.alignleft {
  float: left;
  margin-right: 1em
}

.wp-block-latest-posts__featured-image.alignright {
  float: right;
  margin-left: 1em
}

.wp-block-latest-posts__featured-image.aligncenter {
  margin-bottom: 1em;
  text-align: center
}

ol,
ul {
  box-sizing: border-box
}

ol.has-background,
ul.has-background {
  padding: 1.25em 2.375em
}

.wp-block-media-text {
  box-sizing: border-box;
  /*!rtl:begin:ignore*/
  direction: ltr;
  /*!rtl:end:ignore*/
  display: grid;
  grid-template-columns: 50% 1fr;
  grid-template-rows: auto
}

.wp-block-media-text.has-media-on-the-right {
  grid-template-columns: 1fr 50%
}

.wp-block-media-text.is-vertically-aligned-top .wp-block-media-text__content,
.wp-block-media-text.is-vertically-aligned-top .wp-block-media-text__media {
  align-self: start
}

.wp-block-media-text .wp-block-media-text__content,
.wp-block-media-text .wp-block-media-text__media,
.wp-block-media-text.is-vertically-aligned-center .wp-block-media-text__content,
.wp-block-media-text.is-vertically-aligned-center .wp-block-media-text__media {
  align-self: center
}

.wp-block-media-text.is-vertically-aligned-bottom .wp-block-media-text__content,
.wp-block-media-text.is-vertically-aligned-bottom .wp-block-media-text__media {
  align-self: end
}

.wp-block-media-text .wp-block-media-text__media {
  /*!rtl:begin:ignore*/
  grid-column: 1;
  grid-row: 1;
  /*!rtl:end:ignore*/
  margin: 0
}

.wp-block-media-text .wp-block-media-text__content {
  direction: ltr;
  /*!rtl:begin:ignore*/
  grid-column: 2;
  grid-row: 1;
  /*!rtl:end:ignore*/
  padding: 0 8%;
  word-break: break-word
}

.wp-block-media-text.has-media-on-the-right .wp-block-media-text__media {
  /*!rtl:begin:ignore*/
  grid-column: 2;
  grid-row: 1
    /*!rtl:end:ignore*/
}

.wp-block-media-text.has-media-on-the-right .wp-block-media-text__content {
  /*!rtl:begin:ignore*/
  grid-column: 1;
  grid-row: 1
    /*!rtl:end:ignore*/
}

.wp-block-media-text__media img,
.wp-block-media-text__media video {
  height: auto;
  max-width: unset;
  vertical-align: middle;
  width: 100%
}

.wp-block-media-text.is-image-fill .wp-block-media-text__media {
  background-size: cover;
  height: 100%;
  min-height: 250px
}

.wp-block-media-text.is-image-fill .wp-block-media-text__media>a {
  display: block;
  height: 100%
}

.wp-block-media-text.is-image-fill .wp-block-media-text__media img {
  clip: rect(0, 0, 0, 0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px
}

@media (max-width:600px) {
  .wp-block-media-text.is-stacked-on-mobile {
    grid-template-columns: 100% !important
  }

  .wp-block-media-text.is-stacked-on-mobile .wp-block-media-text__media {
    grid-column: 1;
    grid-row: 1
  }

  .wp-block-media-text.is-stacked-on-mobile .wp-block-media-text__content {
    grid-column: 1;
    grid-row: 2
  }
}

.wp-block-navigation {
  --navigation-layout-justification-setting: flex-start;
  --navigation-layout-direction: row;
  --navigation-layout-wrap: wrap;
  --navigation-layout-justify: flex-start;
  --navigation-layout-align: center;
  position: relative
}

.wp-block-navigation ul {
  margin-bottom: 0;
  margin-left: 0;
  margin-top: 0;
  padding-left: 0
}

.wp-block-navigation ul,
.wp-block-navigation ul li {
  list-style: none;
  padding: 0
}

.wp-block-navigation .wp-block-navigation-item {
  align-items: center;
  display: flex;
  position: relative
}

.wp-block-navigation .wp-block-navigation-item .wp-block-navigation__submenu-container:empty {
  display: none
}

.wp-block-navigation .wp-block-navigation-item__content {
  display: block
}

.wp-block-navigation .wp-block-navigation-item__content.wp-block-navigation-item__content {
  color: inherit
}

.wp-block-navigation.has-text-decoration-underline .wp-block-navigation-item__content,
.wp-block-navigation.has-text-decoration-underline .wp-block-navigation-item__content:active,
.wp-block-navigation.has-text-decoration-underline .wp-block-navigation-item__content:focus {
  text-decoration: underline
}

.wp-block-navigation.has-text-decoration-line-through .wp-block-navigation-item__content,
.wp-block-navigation.has-text-decoration-line-through .wp-block-navigation-item__content:active,
.wp-block-navigation.has-text-decoration-line-through .wp-block-navigation-item__content:focus {
  text-decoration: line-through
}

.wp-block-navigation:where(:not([class*=has-text-decoration])) a {
  text-decoration: none
}

.wp-block-navigation:where(:not([class*=has-text-decoration])) a:active,
.wp-block-navigation:where(:not([class*=has-text-decoration])) a:focus {
  text-decoration: none
}

.wp-block-navigation .wp-block-navigation__submenu-icon {
  align-self: center;
  background-color: inherit;
  border: none;
  color: currentColor;
  display: inline-block;
  font-size: inherit;
  height: .6em;
  line-height: 0;
  margin-left: .25em;
  padding: 0;
  width: .6em
}

.wp-block-navigation .wp-block-navigation__submenu-icon svg {
  stroke: currentColor;
  display: inline-block;
  height: inherit;
  margin-top: .075em;
  width: inherit
}

.wp-block-navigation.is-vertical {
  --navigation-layout-direction: column;
  --navigation-layout-justify: initial;
  --navigation-layout-align: flex-start
}

.wp-block-navigation.no-wrap {
  --navigation-layout-wrap: nowrap
}

.wp-block-navigation.items-justified-center {
  --navigation-layout-justification-setting: center;
  --navigation-layout-justify: center
}

.wp-block-navigation.items-justified-center.is-vertical {
  --navigation-layout-align: center
}

.wp-block-navigation.items-justified-right {
  --navigation-layout-justification-setting: flex-end;
  --navigation-layout-justify: flex-end
}

.wp-block-navigation.items-justified-right.is-vertical {
  --navigation-layout-align: flex-end
}

.wp-block-navigation.items-justified-space-between {
  --navigation-layout-justification-setting: space-between;
  --navigation-layout-justify: space-between
}

.wp-block-navigation .has-child .wp-block-navigation__submenu-container {
  align-items: normal;
  background-color: inherit;
  color: inherit;
  display: flex;
  flex-direction: column;
  height: 0;
  left: -1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  top: 100%;
  transition: opacity .1s linear;
  visibility: hidden;
  width: 0;
  z-index: 2
}

.wp-block-navigation .has-child .wp-block-navigation__submenu-container>.wp-block-navigation-item>.wp-block-navigation-item__content {
  display: flex;
  flex-grow: 1
}

.wp-block-navigation .has-child .wp-block-navigation__submenu-container>.wp-block-navigation-item>.wp-block-navigation-item__content .wp-block-navigation__submenu-icon {
  margin-left: auto;
  margin-right: 0
}

.wp-block-navigation .has-child .wp-block-navigation__submenu-container .wp-block-navigation-item__content {
  margin: 0
}

@media (min-width:782px) {
  .wp-block-navigation .has-child .wp-block-navigation__submenu-container .wp-block-navigation__submenu-container {
    left: 100%;
    top: -1px
  }

  .wp-block-navigation .has-child .wp-block-navigation__submenu-container .wp-block-navigation__submenu-container:before {
    background: transparent;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    right: 100%;
    width: .5em
  }

  .wp-block-navigation .has-child .wp-block-navigation__submenu-container .wp-block-navigation__submenu-icon {
    margin-right: .25em
  }

  .wp-block-navigation .has-child .wp-block-navigation__submenu-container .wp-block-navigation__submenu-icon svg {
    transform: rotate(-90deg)
  }
}

.wp-block-navigation .has-child:not(.open-on-click):hover>.wp-block-navigation__submenu-container {
  height: auto;
  min-width: 200px;
  opacity: 1;
  overflow: visible;
  visibility: visible;
  width: auto
}

.wp-block-navigation .has-child:not(.open-on-click):not(.open-on-hover-click):focus-within>.wp-block-navigation__submenu-container {
  height: auto;
  min-width: 200px;
  opacity: 1;
  overflow: visible;
  visibility: visible;
  width: auto
}

.wp-block-navigation .has-child .wp-block-navigation-submenu__toggle[aria-expanded=true]~.wp-block-navigation__submenu-container {
  height: auto;
  min-width: 200px;
  opacity: 1;
  overflow: visible;
  visibility: visible;
  width: auto
}

.wp-block-navigation.has-background .has-child .wp-block-navigation__submenu-container {
  left: 0;
  top: 100%
}

@media (min-width:782px) {
  .wp-block-navigation.has-background .has-child .wp-block-navigation__submenu-container .wp-block-navigation__submenu-container {
    left: 100%;
    top: 0
  }
}

.wp-block-navigation-submenu {
  display: flex;
  position: relative
}

.wp-block-navigation-submenu .wp-block-navigation__submenu-icon svg {
  stroke: currentColor
}

button.wp-block-navigation-item__content {
  background-color: transparent;
  border: none;
  color: currentColor;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  text-align: left;
  text-transform: inherit
}

.wp-block-navigation-submenu__toggle {
  cursor: pointer
}

.wp-block-navigation-item.open-on-click .wp-block-navigation-submenu__toggle {
  padding-right: .85em
}

.wp-block-navigation-item.open-on-click .wp-block-navigation-submenu__toggle+.wp-block-navigation__submenu-icon {
  margin-left: -.6em;
  pointer-events: none
}

.wp-block-navigation .wp-block-page-list,
.wp-block-navigation__container,
.wp-block-navigation__responsive-close,
.wp-block-navigation__responsive-container,
.wp-block-navigation__responsive-container-content,
.wp-block-navigation__responsive-dialog {
  gap: inherit
}

:where(.wp-block-navigation.has-background .wp-block-navigation-item a:not(.wp-element-button)),
:where(.wp-block-navigation.has-background .wp-block-navigation-submenu a:not(.wp-element-button)) {
  padding: .5em 1em
}

:where(.wp-block-navigation .wp-block-navigation__submenu-container .wp-block-navigation-item a:not(.wp-element-button)),
:where(.wp-block-navigation .wp-block-navigation__submenu-container .wp-block-navigation-submenu a:not(.wp-element-button)),
:where(.wp-block-navigation .wp-block-navigation__submenu-container .wp-block-navigation-submenu button.wp-block-navigation-item__content),
:where(.wp-block-navigation .wp-block-navigation__submenu-container .wp-block-pages-list__item button.wp-block-navigation-item__content) {
  padding: .5em 1em
}

.wp-block-navigation.items-justified-right .wp-block-navigation__container .has-child .wp-block-navigation__submenu-container,
.wp-block-navigation.items-justified-right .wp-block-page-list>.has-child .wp-block-navigation__submenu-container,
.wp-block-navigation.items-justified-space-between .wp-block-page-list>.has-child:last-child .wp-block-navigation__submenu-container,
.wp-block-navigation.items-justified-space-between>.wp-block-navigation__container>.has-child:last-child .wp-block-navigation__submenu-container {
  left: auto;
  right: 0
}

.wp-block-navigation.items-justified-right .wp-block-navigation__container .has-child .wp-block-navigation__submenu-container .wp-block-navigation__submenu-container,
.wp-block-navigation.items-justified-right .wp-block-page-list>.has-child .wp-block-navigation__submenu-container .wp-block-navigation__submenu-container,
.wp-block-navigation.items-justified-space-between .wp-block-page-list>.has-child:last-child .wp-block-navigation__submenu-container .wp-block-navigation__submenu-container,
.wp-block-navigation.items-justified-space-between>.wp-block-navigation__container>.has-child:last-child .wp-block-navigation__submenu-container .wp-block-navigation__submenu-container {
  left: -1px;
  right: -1px
}

@media (min-width:782px) {

  .wp-block-navigation.items-justified-right .wp-block-navigation__container .has-child .wp-block-navigation__submenu-container .wp-block-navigation__submenu-container,
  .wp-block-navigation.items-justified-right .wp-block-page-list>.has-child .wp-block-navigation__submenu-container .wp-block-navigation__submenu-container,
  .wp-block-navigation.items-justified-space-between .wp-block-page-list>.has-child:last-child .wp-block-navigation__submenu-container .wp-block-navigation__submenu-container,
  .wp-block-navigation.items-justified-space-between>.wp-block-navigation__container>.has-child:last-child .wp-block-navigation__submenu-container .wp-block-navigation__submenu-container {
    left: auto;
    right: 100%
  }
}

.wp-block-navigation:not(.has-background) .wp-block-navigation__submenu-container {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .15);
  color: #000
}

.wp-block-navigation__container {
  align-items: var(--navigation-layout-align, initial);
  display: flex;
  flex-direction: var(--navigation-layout-direction, initial);
  flex-wrap: var(--navigation-layout-wrap, wrap);
  justify-content: var(--navigation-layout-justify, initial);
  list-style: none;
  margin: 0;
  padding-left: 0
}

.wp-block-navigation__container .is-responsive {
  display: none
}

.wp-block-navigation__container:only-child,
.wp-block-page-list:only-child {
  flex-grow: 1
}

@keyframes overlay-menu__fade-in-animation {
  0% {
    opacity: 0;
    transform: translateY(.5em)
  }

  to {
    opacity: 1;
    transform: translateY(0)
  }
}

.wp-block-navigation__responsive-container {
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 0
}

.wp-block-navigation__responsive-container .wp-block-navigation-link a {
  color: inherit
}

.wp-block-navigation__responsive-container .wp-block-navigation__responsive-container-content {
  align-items: var(--navigation-layout-align, initial);
  display: flex;
  flex-direction: var(--navigation-layout-direction, initial);
  flex-wrap: var(--navigation-layout-wrap, wrap);
  justify-content: var(--navigation-layout-justify, initial)
}

.wp-block-navigation__responsive-container:not(.is-menu-open.is-menu-open) {
  background-color: inherit !important;
  color: inherit !important
}

.wp-block-navigation__responsive-container.is-menu-open {
  animation: overlay-menu__fade-in-animation .1s ease-out;
  animation-fill-mode: forwards;
  background-color: inherit;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: var(--wp--style--root--padding-top, 2rem) var(--wp--style--root--padding-right, 2rem) var(--wp--style--root--padding-bottom, 2rem) var(--wp--style--root--padding-left, 2rem);
  z-index: 100000
}

@media (prefers-reduced-motion:reduce) {
  .wp-block-navigation__responsive-container.is-menu-open {
    animation-delay: 0s;
    animation-duration: 1ms
  }
}

.wp-block-navigation__responsive-container.is-menu-open .wp-block-navigation__responsive-container-content {
  align-items: var(--navigation-layout-justification-setting, inherit);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: visible;
  padding-top: calc(2rem + 24px)
}

.wp-block-navigation__responsive-container.is-menu-open .wp-block-navigation__responsive-container-content,
.wp-block-navigation__responsive-container.is-menu-open .wp-block-navigation__responsive-container-content .wp-block-navigation__container,
.wp-block-navigation__responsive-container.is-menu-open .wp-block-navigation__responsive-container-content .wp-block-page-list {
  justify-content: flex-start
}

.wp-block-navigation__responsive-container.is-menu-open .wp-block-navigation__responsive-container-content .wp-block-navigation__submenu-icon {
  display: none
}

.wp-block-navigation__responsive-container.is-menu-open .wp-block-navigation__responsive-container-content .has-child .wp-block-navigation__submenu-container {
  border: none;
  height: auto;
  min-width: 200px;
  opacity: 1;
  overflow: initial;
  padding-left: 2rem;
  padding-right: 2rem;
  position: static;
  visibility: visible;
  width: auto
}

.wp-block-navigation__responsive-container.is-menu-open .wp-block-navigation__responsive-container-content .wp-block-navigation__container,
.wp-block-navigation__responsive-container.is-menu-open .wp-block-navigation__responsive-container-content .wp-block-navigation__submenu-container {
  gap: inherit
}

.wp-block-navigation__responsive-container.is-menu-open .wp-block-navigation__responsive-container-content .wp-block-navigation__submenu-container {
  padding-top: var(--wp--style--block-gap, 2em)
}

.wp-block-navigation__responsive-container.is-menu-open .wp-block-navigation__responsive-container-content .wp-block-navigation-item__content {
  padding: 0
}

.wp-block-navigation__responsive-container.is-menu-open .wp-block-navigation__responsive-container-content .wp-block-navigation-item,
.wp-block-navigation__responsive-container.is-menu-open .wp-block-navigation__responsive-container-content .wp-block-navigation__container,
.wp-block-navigation__responsive-container.is-menu-open .wp-block-navigation__responsive-container-content .wp-block-page-list {
  align-items: var(--navigation-layout-justification-setting, initial);
  display: flex;
  flex-direction: column
}

.wp-block-navigation__responsive-container.is-menu-open .wp-block-navigation-item,
.wp-block-navigation__responsive-container.is-menu-open .wp-block-navigation-item .wp-block-navigation__submenu-container,
.wp-block-navigation__responsive-container.is-menu-open .wp-block-page-list {
  background: transparent !important;
  color: inherit !important
}

.wp-block-navigation__responsive-container.is-menu-open .wp-block-navigation__submenu-container.wp-block-navigation__submenu-container.wp-block-navigation__submenu-container.wp-block-navigation__submenu-container {
  left: auto;
  right: auto
}

@media (min-width:600px) {
  .wp-block-navigation__responsive-container:not(.hidden-by-default):not(.is-menu-open) {
    background-color: inherit;
    display: block;
    position: relative;
    width: 100%;
    z-index: auto
  }

  .wp-block-navigation__responsive-container:not(.hidden-by-default):not(.is-menu-open) .wp-block-navigation__responsive-container-close {
    display: none
  }

  .wp-block-navigation__responsive-container.is-menu-open .wp-block-navigation__submenu-container.wp-block-navigation__submenu-container.wp-block-navigation__submenu-container.wp-block-navigation__submenu-container {
    left: 0
  }
}

.wp-block-navigation:not(.has-background) .wp-block-navigation__responsive-container.is-menu-open {
  background-color: #fff;
  color: #000
}

.wp-block-navigation__toggle_button_label {
  font-size: 1rem;
  font-weight: 700
}

.wp-block-navigation__responsive-container-close,
.wp-block-navigation__responsive-container-open {
  background: transparent;
  border: none;
  color: currentColor;
  cursor: pointer;
  margin: 0;
  padding: 0;
  text-transform: inherit;
  vertical-align: middle
}

.wp-block-navigation__responsive-container-close svg,
.wp-block-navigation__responsive-container-open svg {
  fill: currentColor;
  display: block;
  height: 24px;
  pointer-events: none;
  width: 24px
}

.wp-block-navigation__responsive-container-open {
  display: flex
}

.wp-block-navigation__responsive-container-open.wp-block-navigation__responsive-container-open.wp-block-navigation__responsive-container-open {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit
}

@media (min-width:600px) {
  .wp-block-navigation__responsive-container-open:not(.always-shown) {
    display: none
  }
}

.wp-block-navigation__responsive-container-close {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2
}

.wp-block-navigation__responsive-container-close.wp-block-navigation__responsive-container-close.wp-block-navigation__responsive-container-close {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit
}

.wp-block-navigation__responsive-close {
  width: 100%
}

.has-modal-open .wp-block-navigation__responsive-close {
  margin-left: auto;
  margin-right: auto;
  max-width: var(--wp--style--global--wide-size, 100%)
}

.wp-block-navigation__responsive-close:focus {
  outline: none
}

.is-menu-open .wp-block-navigation__responsive-close,
.is-menu-open .wp-block-navigation__responsive-container-content,
.is-menu-open .wp-block-navigation__responsive-dialog {
  box-sizing: border-box
}

.wp-block-navigation__responsive-dialog {
  position: relative
}

.has-modal-open .admin-bar .is-menu-open .wp-block-navigation__responsive-dialog {
  margin-top: 46px
}

@media (min-width:782px) {
  .has-modal-open .admin-bar .is-menu-open .wp-block-navigation__responsive-dialog {
    margin-top: 32px
  }
}

html.has-modal-open {
  overflow: hidden
}

.wp-block-navigation .wp-block-navigation-item__label {
  overflow-wrap: break-word;
  word-break: normal
}

.wp-block-navigation .wp-block-navigation-item__description {
  display: none
}

.wp-block-navigation .wp-block-page-list {
  align-items: var(--navigation-layout-align, initial);
  background-color: inherit;
  display: flex;
  flex-direction: var(--navigation-layout-direction, initial);
  flex-wrap: var(--navigation-layout-wrap, wrap);
  justify-content: var(--navigation-layout-justify, initial)
}

.wp-block-navigation .wp-block-navigation-item {
  background-color: inherit
}

.is-small-text {
  font-size: .875em
}

.is-regular-text {
  font-size: 1em
}

.is-large-text {
  font-size: 2.25em
}

.is-larger-text {
  font-size: 3em
}

.has-drop-cap:not(:focus):first-letter {
  float: left;
  font-size: 8.4em;
  font-style: normal;
  font-weight: 100;
  line-height: .68;
  margin: .05em .1em 0 0;
  text-transform: uppercase
}

body.rtl .has-drop-cap:not(:focus):first-letter {
  float: none;
  margin-left: .1em
}

p.has-drop-cap.has-background {
  overflow: hidden
}

p.has-background {
  padding: 1.25em 2.375em
}

:where(p.has-text-color:not(.has-link-color)) a {
  color: inherit
}

.wp-block-post-author {
  display: flex;
  flex-wrap: wrap
}

.wp-block-post-author__byline {
  font-size: .5em;
  margin-bottom: 0;
  margin-top: 0;
  width: 100%
}

.wp-block-post-author__avatar {
  margin-right: 1em
}

.wp-block-post-author__bio {
  font-size: .7em;
  margin-bottom: .7em
}

.wp-block-post-author__content {
  flex-basis: 0;
  flex-grow: 1
}

.wp-block-post-author__name {
  margin: 0
}

.wp-block-post-comments-form {
  box-sizing: border-box
}

.wp-block-post-comments-form[style*=font-weight] :where(.comment-reply-title) {
  font-weight: inherit
}

.wp-block-post-comments-form[style*=font-family] :where(.comment-reply-title) {
  font-family: inherit
}

.wp-block-post-comments-form[class*=-font-size] :where(.comment-reply-title),
.wp-block-post-comments-form[style*=font-size] :where(.comment-reply-title) {
  font-size: inherit
}

.wp-block-post-comments-form[style*=line-height] :where(.comment-reply-title) {
  line-height: inherit
}

.wp-block-post-comments-form[style*=font-style] :where(.comment-reply-title) {
  font-style: inherit
}

.wp-block-post-comments-form[style*=letter-spacing] :where(.comment-reply-title) {
  letter-spacing: inherit
}

.wp-block-post-comments-form input[type=submit] {
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  overflow-wrap: break-word;
  text-align: center
}

.wp-block-post-comments-form input:not([type=submit]),
.wp-block-post-comments-form textarea {
  border: 1px solid #949494;
  font-family: inherit;
  font-size: 1em
}

.wp-block-post-comments-form input:not([type=submit]):not([type=checkbox]),
.wp-block-post-comments-form textarea {
  padding: calc(.667em + 2px)
}

.wp-block-post-comments-form .comment-form input:not([type=submit]):not([type=checkbox]):not([type=hidden]),
.wp-block-post-comments-form .comment-form textarea {
  box-sizing: border-box;
  display: block;
  width: 100%
}

.wp-block-post-comments-form .comment-form-author label,
.wp-block-post-comments-form .comment-form-email label,
.wp-block-post-comments-form .comment-form-url label {
  display: block;
  margin-bottom: .25em
}

.wp-block-post-comments-form .comment-form-cookies-consent {
  display: flex;
  gap: .25em
}

.wp-block-post-comments-form .comment-form-cookies-consent #wp-comment-cookies-consent {
  margin-top: .35em
}

.wp-block-post-comments-form .comment-reply-title {
  margin-bottom: 0
}

.wp-block-post-comments-form .comment-reply-title :where(small) {
  font-size: var(--wp--preset--font-size--medium, smaller);
  margin-left: .5em
}

.wp-block-post-date {
  box-sizing: border-box
}

:where(.wp-block-post-excerpt) {
  margin-bottom: var(--wp--style--block-gap);
  margin-top: var(--wp--style--block-gap)
}

.wp-block-post-excerpt__excerpt {
  margin-bottom: 0;
  margin-top: 0
}

.wp-block-post-excerpt__more-text {
  margin-bottom: 0;
  margin-top: var(--wp--style--block-gap)
}

.wp-block-post-excerpt__more-link {
  display: inline-block
}

.wp-block-post-featured-image {
  margin-left: 0;
  margin-right: 0
}

.wp-block-post-featured-image a {
  display: block;
  height: 100%
}

.wp-block-post-featured-image img {
  box-sizing: border-box;
  height: auto;
  max-width: 100%;
  vertical-align: bottom;
  width: 100%
}

.wp-block-post-featured-image.alignfull img,
.wp-block-post-featured-image.alignwide img {
  width: 100%
}

.wp-block-post-featured-image .wp-block-post-featured-image__overlay.has-background-dim {
  background-color: #000;
  inset: 0;
  position: absolute
}

.wp-block-post-featured-image {
  position: relative
}

.wp-block-post-featured-image .wp-block-post-featured-image__overlay.has-background-gradient {
  background-color: transparent
}

.wp-block-post-featured-image .wp-block-post-featured-image__overlay.has-background-dim-0 {
  opacity: 0
}

.wp-block-post-featured-image .wp-block-post-featured-image__overlay.has-background-dim-10 {
  opacity: .1
}

.wp-block-post-featured-image .wp-block-post-featured-image__overlay.has-background-dim-20 {
  opacity: .2
}

.wp-block-post-featured-image .wp-block-post-featured-image__overlay.has-background-dim-30 {
  opacity: .3
}

.wp-block-post-featured-image .wp-block-post-featured-image__overlay.has-background-dim-40 {
  opacity: .4
}

.wp-block-post-featured-image .wp-block-post-featured-image__overlay.has-background-dim-50 {
  opacity: .5
}

.wp-block-post-featured-image .wp-block-post-featured-image__overlay.has-background-dim-60 {
  opacity: .6
}

.wp-block-post-featured-image .wp-block-post-featured-image__overlay.has-background-dim-70 {
  opacity: .7
}

.wp-block-post-featured-image .wp-block-post-featured-image__overlay.has-background-dim-80 {
  opacity: .8
}

.wp-block-post-featured-image .wp-block-post-featured-image__overlay.has-background-dim-90 {
  opacity: .9
}

.wp-block-post-featured-image .wp-block-post-featured-image__overlay.has-background-dim-100 {
  opacity: 1
}

.wp-block-post-navigation-link .wp-block-post-navigation-link__arrow-previous {
  display: inline-block;
  margin-right: 1ch
}

.wp-block-post-navigation-link .wp-block-post-navigation-link__arrow-previous:not(.is-arrow-chevron) {
  transform: scaleX(1)
}

.wp-block-post-navigation-link .wp-block-post-navigation-link__arrow-next {
  display: inline-block;
  margin-left: 1ch
}

.wp-block-post-navigation-link .wp-block-post-navigation-link__arrow-next:not(.is-arrow-chevron) {
  transform: scaleX(1)
}

.wp-block-post-terms {
  box-sizing: border-box
}

.wp-block-post-terms .wp-block-post-terms__separator {
  white-space: pre-wrap
}

.wp-block-post-time-to-read,
.wp-block-post-title {
  box-sizing: border-box
}

.wp-block-post-title {
  word-break: break-word
}

.wp-block-post-title a {
  display: inline-block
}

.wp-block-preformatted {
  white-space: pre-wrap
}

.wp-block-preformatted.has-background {
  padding: 1.25em 2.375em
}

.wp-block-pullquote {
  box-sizing: border-box;
  overflow-wrap: break-word;
  padding: 3em 0;
  text-align: center
}

.wp-block-pullquote blockquote,
.wp-block-pullquote cite,
.wp-block-pullquote p {
  color: inherit
}

.wp-block-pullquote.alignleft,
.wp-block-pullquote.alignright {
  max-width: 420px
}

.wp-block-pullquote cite,
.wp-block-pullquote footer {
  position: relative
}

.wp-block-pullquote .has-text-color a {
  color: inherit
}

:where(.wp-block-pullquote) {
  margin: 0 0 1em
}

.wp-block-pullquote.has-text-align-left blockquote {
  text-align: left
}

.wp-block-pullquote.has-text-align-right blockquote {
  text-align: right
}

.wp-block-pullquote.is-style-solid-color {
  border: none
}

.wp-block-pullquote.is-style-solid-color blockquote {
  margin-left: auto;
  margin-right: auto;
  max-width: 60%
}

.wp-block-pullquote.is-style-solid-color blockquote p {
  font-size: 2em;
  margin-bottom: 0;
  margin-top: 0
}

.wp-block-pullquote.is-style-solid-color blockquote cite {
  font-style: normal;
  text-transform: none
}

.wp-block-pullquote cite {
  color: inherit
}

.wp-block-post-template {
  list-style: none;
  margin-bottom: 0;
  margin-top: 0;
  max-width: 100%;
  padding: 0
}

.wp-block-post-template.wp-block-post-template {
  background: none
}

.wp-block-post-template.is-flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.25em
}

.wp-block-post-template.is-flex-container>li {
  margin: 0;
  width: 100%
}

@media (min-width:600px) {
  .wp-block-post-template.is-flex-container.is-flex-container.columns-2>li {
    width: calc(50% - .625em)
  }

  .wp-block-post-template.is-flex-container.is-flex-container.columns-3>li {
    width: calc(33.33333% - .83333em)
  }

  .wp-block-post-template.is-flex-container.is-flex-container.columns-4>li {
    width: calc(25% - .9375em)
  }

  .wp-block-post-template.is-flex-container.is-flex-container.columns-5>li {
    width: calc(20% - 1em)
  }

  .wp-block-post-template.is-flex-container.is-flex-container.columns-6>li {
    width: calc(16.66667% - 1.04167em)
  }
}

@media (max-width:600px) {
  .wp-block-post-template-is-layout-grid.wp-block-post-template-is-layout-grid.wp-block-post-template-is-layout-grid.wp-block-post-template-is-layout-grid {
    grid-template-columns: 1fr
  }
}

.wp-block-query-pagination>.wp-block-query-pagination-next,
.wp-block-query-pagination>.wp-block-query-pagination-numbers,
.wp-block-query-pagination>.wp-block-query-pagination-previous {
  margin-bottom: .5em;
  margin-right: .5em
}

.wp-block-query-pagination>.wp-block-query-pagination-next:last-child,
.wp-block-query-pagination>.wp-block-query-pagination-numbers:last-child,
.wp-block-query-pagination>.wp-block-query-pagination-previous:last-child {
  margin-right: 0
}

.wp-block-query-pagination.is-content-justification-space-between>.wp-block-query-pagination-next:last-of-type {
  margin-inline-start: auto
}

.wp-block-query-pagination.is-content-justification-space-between>.wp-block-query-pagination-previous:first-child {
  margin-inline-end: auto
}

.wp-block-query-pagination .wp-block-query-pagination-previous-arrow {
  display: inline-block;
  margin-right: 1ch
}

.wp-block-query-pagination .wp-block-query-pagination-previous-arrow:not(.is-arrow-chevron) {
  transform: scaleX(1)
}

.wp-block-query-pagination .wp-block-query-pagination-next-arrow {
  display: inline-block;
  margin-left: 1ch
}

.wp-block-query-pagination .wp-block-query-pagination-next-arrow:not(.is-arrow-chevron) {
  transform: scaleX(1)
}

.wp-block-query-pagination.aligncenter {
  justify-content: center
}

.wp-block-query-title,
.wp-block-quote {
  box-sizing: border-box
}

.wp-block-quote {
  overflow-wrap: break-word
}

.wp-block-quote.is-large:where(:not(.is-style-plain)),
.wp-block-quote.is-style-large:where(:not(.is-style-plain)) {
  margin-bottom: 1em;
  padding: 0 1em
}

.wp-block-quote.is-large:where(:not(.is-style-plain)) p,
.wp-block-quote.is-style-large:where(:not(.is-style-plain)) p {
  font-size: 1.5em;
  font-style: italic;
  line-height: 1.6
}

.wp-block-quote.is-large:where(:not(.is-style-plain)) cite,
.wp-block-quote.is-large:where(:not(.is-style-plain)) footer,
.wp-block-quote.is-style-large:where(:not(.is-style-plain)) cite,
.wp-block-quote.is-style-large:where(:not(.is-style-plain)) footer {
  font-size: 1.125em;
  text-align: right
}

.wp-block-read-more {
  display: block;
  width: -moz-fit-content;
  width: fit-content
}

.wp-block-read-more:not([style*=text-decoration]),
.wp-block-read-more:not([style*=text-decoration]):active,
.wp-block-read-more:not([style*=text-decoration]):focus {
  text-decoration: none
}

ul.wp-block-rss {
  list-style: none;
  padding: 0
}

ul.wp-block-rss.wp-block-rss {
  box-sizing: border-box
}

ul.wp-block-rss.alignleft {
  margin-right: 2em
}

ul.wp-block-rss.alignright {
  margin-left: 2em
}

ul.wp-block-rss.is-grid {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0
}

ul.wp-block-rss.is-grid li {
  margin: 0 1em 1em 0;
  width: 100%
}

@media (min-width:600px) {
  ul.wp-block-rss.columns-2 li {
    width: calc(50% - 1em)
  }

  ul.wp-block-rss.columns-3 li {
    width: calc(33.33333% - 1em)
  }

  ul.wp-block-rss.columns-4 li {
    width: calc(25% - 1em)
  }

  ul.wp-block-rss.columns-5 li {
    width: calc(20% - 1em)
  }

  ul.wp-block-rss.columns-6 li {
    width: calc(16.66667% - 1em)
  }
}

.wp-block-rss__item-author,
.wp-block-rss__item-publish-date {
  display: block;
  font-size: .8125em
}

.wp-block-search__button {
  margin-left: 10px;
  word-break: normal
}

.wp-block-search__button.has-icon {
  line-height: 0
}

.wp-block-search__button svg {
  fill: currentColor;
  min-height: 24px;
  min-width: 24px;
  vertical-align: text-bottom
}

:where(.wp-block-search__button) {
  border: 1px solid #ccc;
  padding: 6px 10px
}

.wp-block-search__inside-wrapper {
  display: flex;
  flex: auto;
  flex-wrap: nowrap;
  max-width: 100%
}

.wp-block-search__label {
  width: 100%
}

.wp-block-search__input {
  -webkit-appearance: initial;
  appearance: none;
  border: 1px solid #949494;
  flex-grow: 1;
  margin-left: 0;
  margin-right: 0;
  min-width: 3rem;
  padding: 8px;
  text-decoration: unset !important
}

.wp-block-search.wp-block-search__button-only .wp-block-search__button {
  margin-left: 0
}

:where(.wp-block-search__button-inside .wp-block-search__inside-wrapper) {
  border: 1px solid #949494;
  box-sizing: border-box;
  padding: 4px
}

:where(.wp-block-search__button-inside .wp-block-search__inside-wrapper) .wp-block-search__input {
  border: none;
  border-radius: 0;
  padding: 0 4px
}

:where(.wp-block-search__button-inside .wp-block-search__inside-wrapper) .wp-block-search__input:focus {
  outline: none
}

:where(.wp-block-search__button-inside .wp-block-search__inside-wrapper) :where(.wp-block-search__button) {
  padding: 4px 8px
}

.wp-block-search.aligncenter .wp-block-search__inside-wrapper {
  margin: auto
}

.wp-block-search__button-behavior-expand .wp-block-search__inside-wrapper {
  min-width: 0 !important;
  transition-property: width
}

.wp-block-search__button-behavior-expand .wp-block-search__input {
  flex-basis: 100%;
  transition-duration: .3s
}

.wp-block-search__button-behavior-expand.wp-block-search__searchfield-hidden,
.wp-block-search__button-behavior-expand.wp-block-search__searchfield-hidden .wp-block-search__inside-wrapper {
  overflow: hidden
}

.wp-block-search__button-behavior-expand.wp-block-search__searchfield-hidden .wp-block-search__input {
  border-left-width: 0 !important;
  border-right-width: 0 !important;
  flex-basis: 0;
  flex-grow: 0;
  margin: 0;
  min-width: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 0 !important
}

.wp-block[data-align=right] .wp-block-search__button-behavior-expand .wp-block-search__inside-wrapper {
  float: right
}

.wp-block-separator {
  border: 1px solid;
  border-left: none;
  border-right: none
}

.wp-block-separator.is-style-dots {
  background: none !important;
  border: none;
  height: auto;
  line-height: 1;
  text-align: center
}

.wp-block-separator.is-style-dots:before {
  color: currentColor;
  content: "···";
  font-family: serif;
  font-size: 1.5em;
  letter-spacing: 2em;
  padding-left: 2em
}

.wp-block-site-logo {
  box-sizing: border-box;
  line-height: 0
}

.wp-block-site-logo a {
  display: inline-block
}

.wp-block-site-logo.is-default-size img {
  height: auto;
  width: 120px
}

.wp-block-site-logo img {
  height: auto;
  max-width: 100%
}

.wp-block-site-logo a,
.wp-block-site-logo img {
  border-radius: inherit
}

.wp-block-site-logo.aligncenter {
  margin-left: auto;
  margin-right: auto;
  text-align: center
}

.wp-block-site-logo.is-style-rounded {
  border-radius: 9999px
}

.wp-block-site-title a {
  color: inherit
}

.wp-block-social-links {
  background: none;
  box-sizing: border-box;
  margin-left: 0;
  padding-left: 0;
  padding-right: 0;
  text-indent: 0
}

.wp-block-social-links .wp-social-link a,
.wp-block-social-links .wp-social-link a:hover {
  border-bottom: 0;
  box-shadow: none;
  text-decoration: none
}

.wp-block-social-links .wp-social-link a {
  padding: .25em
}

.wp-block-social-links .wp-social-link svg {
  height: 1em;
  width: 1em
}

.wp-block-social-links .wp-social-link span:not(.screen-reader-text) {
  font-size: .65em;
  margin-left: .5em;
  margin-right: .5em
}

.wp-block-social-links.has-small-icon-size {
  font-size: 16px
}

.wp-block-social-links,
.wp-block-social-links.has-normal-icon-size {
  font-size: 24px
}

.wp-block-social-links.has-large-icon-size {
  font-size: 36px
}

.wp-block-social-links.has-huge-icon-size {
  font-size: 48px
}

.wp-block-social-links.aligncenter {
  display: flex;
  justify-content: center
}

.wp-block-social-links.alignright {
  justify-content: flex-end
}

.wp-block-social-link {
  border-radius: 9999px;
  display: block;
  height: auto;
  transition: transform .1s ease
}

@media (prefers-reduced-motion:reduce) {
  .wp-block-social-link {
    transition-delay: 0s;
    transition-duration: 0s
  }
}

.wp-block-social-link a {
  align-items: center;
  display: flex;
  line-height: 0;
  transition: transform .1s ease
}

.wp-block-social-link:hover {
  transform: scale(1.1)
}

.wp-block-social-links .wp-block-social-link .wp-block-social-link-anchor,
.wp-block-social-links .wp-block-social-link .wp-block-social-link-anchor svg,
.wp-block-social-links .wp-block-social-link .wp-block-social-link-anchor:active,
.wp-block-social-links .wp-block-social-link .wp-block-social-link-anchor:hover,
.wp-block-social-links .wp-block-social-link .wp-block-social-link-anchor:visited {
  fill: currentColor;
  color: currentColor
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link {
  background-color: #f0f0f0;
  color: #444
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-amazon {
  background-color: #f90;
  color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-bandcamp {
  background-color: #1ea0c3;
  color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-behance {
  background-color: #0757fe;
  color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-codepen {
  background-color: #1e1f26;
  color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-deviantart {
  background-color: #02e49b;
  color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-dribbble {
  background-color: #e94c89;
  color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-dropbox {
  background-color: #4280ff;
  color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-etsy {
  background-color: #f45800;
  color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-facebook {
  background-color: #1778f2;
  color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-fivehundredpx {
  background-color: #000;
  color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-flickr {
  background-color: #0461dd;
  color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-foursquare {
  background-color: #e65678;
  color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-github {
  background-color: #24292d;
  color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-goodreads {
  background-color: #eceadd;
  color: #382110
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-google {
  background-color: #ea4434;
  color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-instagram {
  background-color: #f00075;
  color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-lastfm {
  background-color: #e21b24;
  color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-linkedin {
  background-color: #0d66c2;
  color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-mastodon {
  background-color: #3288d4;
  color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-medium {
  background-color: #02ab6c;
  color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-meetup {
  background-color: #f6405f;
  color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-patreon {
  background-color: #ff424d;
  color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-pinterest {
  background-color: #e60122;
  color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-pocket {
  background-color: #ef4155;
  color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-reddit {
  background-color: #ff4500;
  color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-skype {
  background-color: #0478d7;
  color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-snapchat {
  stroke: #000;
  background-color: #fefc00;
  color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-soundcloud {
  background-color: #ff5600;
  color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-spotify {
  background-color: #1bd760;
  color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-telegram {
  background-color: #2aabee;
  color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-tiktok {
  background-color: #000;
  color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-tumblr {
  background-color: #011835;
  color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-twitch {
  background-color: #6440a4;
  color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-twitter {
  background-color: #1da1f2;
  color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-vimeo {
  background-color: #1eb7ea;
  color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-vk {
  background-color: #4680c2;
  color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-wordpress {
  background-color: #3499cd;
  color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-whatsapp {
  background-color: #25d366;
  color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-yelp {
  background-color: #d32422;
  color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-youtube {
  background-color: red;
  color: #fff
}

.wp-block-social-links.is-style-logos-only .wp-social-link {
  background: none
}

.wp-block-social-links.is-style-logos-only .wp-social-link a {
  padding: 0
}

.wp-block-social-links.is-style-logos-only .wp-social-link svg {
  height: 1.25em;
  width: 1.25em
}

.wp-block-social-links.is-style-logos-only .wp-social-link-amazon {
  color: #f90
}

.wp-block-social-links.is-style-logos-only .wp-social-link-bandcamp {
  color: #1ea0c3
}

.wp-block-social-links.is-style-logos-only .wp-social-link-behance {
  color: #0757fe
}

.wp-block-social-links.is-style-logos-only .wp-social-link-codepen {
  color: #1e1f26
}

.wp-block-social-links.is-style-logos-only .wp-social-link-deviantart {
  color: #02e49b
}

.wp-block-social-links.is-style-logos-only .wp-social-link-dribbble {
  color: #e94c89
}

.wp-block-social-links.is-style-logos-only .wp-social-link-dropbox {
  color: #4280ff
}

.wp-block-social-links.is-style-logos-only .wp-social-link-etsy {
  color: #f45800
}

.wp-block-social-links.is-style-logos-only .wp-social-link-facebook {
  color: #1778f2
}

.wp-block-social-links.is-style-logos-only .wp-social-link-fivehundredpx {
  color: #000
}

.wp-block-social-links.is-style-logos-only .wp-social-link-flickr {
  color: #0461dd
}

.wp-block-social-links.is-style-logos-only .wp-social-link-foursquare {
  color: #e65678
}

.wp-block-social-links.is-style-logos-only .wp-social-link-github {
  color: #24292d
}

.wp-block-social-links.is-style-logos-only .wp-social-link-goodreads {
  color: #382110
}

.wp-block-social-links.is-style-logos-only .wp-social-link-google {
  color: #ea4434
}

.wp-block-social-links.is-style-logos-only .wp-social-link-instagram {
  color: #f00075
}

.wp-block-social-links.is-style-logos-only .wp-social-link-lastfm {
  color: #e21b24
}

.wp-block-social-links.is-style-logos-only .wp-social-link-linkedin {
  color: #0d66c2
}

.wp-block-social-links.is-style-logos-only .wp-social-link-mastodon {
  color: #3288d4
}

.wp-block-social-links.is-style-logos-only .wp-social-link-medium {
  color: #02ab6c
}

.wp-block-social-links.is-style-logos-only .wp-social-link-meetup {
  color: #f6405f
}

.wp-block-social-links.is-style-logos-only .wp-social-link-patreon {
  color: #ff424d
}

.wp-block-social-links.is-style-logos-only .wp-social-link-pinterest {
  color: #e60122
}

.wp-block-social-links.is-style-logos-only .wp-social-link-pocket {
  color: #ef4155
}

.wp-block-social-links.is-style-logos-only .wp-social-link-reddit {
  color: #ff4500
}

.wp-block-social-links.is-style-logos-only .wp-social-link-skype {
  color: #0478d7
}

.wp-block-social-links.is-style-logos-only .wp-social-link-snapchat {
  stroke: #000;
  color: #fff
}

.wp-block-social-links.is-style-logos-only .wp-social-link-soundcloud {
  color: #ff5600
}

.wp-block-social-links.is-style-logos-only .wp-social-link-spotify {
  color: #1bd760
}

.wp-block-social-links.is-style-logos-only .wp-social-link-telegram {
  color: #2aabee
}

.wp-block-social-links.is-style-logos-only .wp-social-link-tiktok {
  color: #000
}

.wp-block-social-links.is-style-logos-only .wp-social-link-tumblr {
  color: #011835
}

.wp-block-social-links.is-style-logos-only .wp-social-link-twitch {
  color: #6440a4
}

.wp-block-social-links.is-style-logos-only .wp-social-link-twitter {
  color: #1da1f2
}

.wp-block-social-links.is-style-logos-only .wp-social-link-vimeo {
  color: #1eb7ea
}

.wp-block-social-links.is-style-logos-only .wp-social-link-vk {
  color: #4680c2
}

.wp-block-social-links.is-style-logos-only .wp-social-link-whatsapp {
  color: #25d366
}

.wp-block-social-links.is-style-logos-only .wp-social-link-wordpress {
  color: #3499cd
}

.wp-block-social-links.is-style-logos-only .wp-social-link-yelp {
  color: #d32422
}

.wp-block-social-links.is-style-logos-only .wp-social-link-youtube {
  color: red
}

.wp-block-social-links.is-style-pill-shape .wp-social-link {
  width: auto
}

.wp-block-social-links.is-style-pill-shape .wp-social-link a {
  padding-left: .66667em;
  padding-right: .66667em
}

.wp-block-social-links:not(.has-icon-color):not(.has-icon-background-color) .wp-social-link-snapchat .wp-block-social-link-label {
  color: #000
}

.wp-block-spacer {
  clear: both
}

.wp-block-tag-cloud {
  box-sizing: border-box
}

.wp-block-tag-cloud.aligncenter {
  justify-content: center;
  text-align: center
}

.wp-block-tag-cloud.alignfull {
  padding-left: 1em;
  padding-right: 1em
}

.wp-block-tag-cloud a {
  display: inline-block;
  margin-right: 5px
}

.wp-block-tag-cloud span {
  display: inline-block;
  margin-left: 5px;
  text-decoration: none
}

.wp-block-tag-cloud.is-style-outline {
  display: flex;
  flex-wrap: wrap;
  gap: 1ch
}

.wp-block-tag-cloud.is-style-outline a {
  border: 1px solid;
  font-size: unset !important;
  margin-right: 0;
  padding: 1ch 2ch;
  text-decoration: none !important
}

.wp-block-table {
  overflow-x: auto
}

.wp-block-table table {
  border-collapse: collapse;
  width: 100%
}

.wp-block-table thead {
  border-bottom: 3px solid
}

.wp-block-table tfoot {
  border-top: 3px solid
}

.wp-block-table td,
.wp-block-table th {
  border: 1px solid;
  padding: .5em
}

.wp-block-table .has-fixed-layout {
  table-layout: fixed;
  width: 100%
}

.wp-block-table .has-fixed-layout td,
.wp-block-table .has-fixed-layout th {
  word-break: break-word
}

.wp-block-table.aligncenter,
.wp-block-table.alignleft,
.wp-block-table.alignright {
  display: table;
  width: auto
}

.wp-block-table.aligncenter td,
.wp-block-table.aligncenter th,
.wp-block-table.alignleft td,
.wp-block-table.alignleft th,
.wp-block-table.alignright td,
.wp-block-table.alignright th {
  word-break: break-word
}

.wp-block-table .has-subtle-light-gray-background-color {
  background-color: #f3f4f5
}

.wp-block-table .has-subtle-pale-green-background-color {
  background-color: #e9fbe5
}

.wp-block-table .has-subtle-pale-blue-background-color {
  background-color: #e7f5fe
}

.wp-block-table .has-subtle-pale-pink-background-color {
  background-color: #fcf0ef
}

.wp-block-table.is-style-stripes {
  background-color: transparent;
  border-bottom: 1px solid #f0f0f0;
  border-collapse: inherit;
  border-spacing: 0
}

.wp-block-table.is-style-stripes tbody tr:nth-child(odd) {
  background-color: #f0f0f0
}

.wp-block-table.is-style-stripes.has-subtle-light-gray-background-color tbody tr:nth-child(odd) {
  background-color: #f3f4f5
}

.wp-block-table.is-style-stripes.has-subtle-pale-green-background-color tbody tr:nth-child(odd) {
  background-color: #e9fbe5
}

.wp-block-table.is-style-stripes.has-subtle-pale-blue-background-color tbody tr:nth-child(odd) {
  background-color: #e7f5fe
}

.wp-block-table.is-style-stripes.has-subtle-pale-pink-background-color tbody tr:nth-child(odd) {
  background-color: #fcf0ef
}

.wp-block-table.is-style-stripes td,
.wp-block-table.is-style-stripes th {
  border-color: transparent
}

.wp-block-table .has-border-color td,
.wp-block-table .has-border-color th,
.wp-block-table .has-border-color tr,
.wp-block-table .has-border-color>* {
  border-color: inherit
}

.wp-block-table table[style*=border-top-color] tr:first-child,
.wp-block-table table[style*=border-top-color] tr:first-child td,
.wp-block-table table[style*=border-top-color] tr:first-child th,
.wp-block-table table[style*=border-top-color]>*,
.wp-block-table table[style*=border-top-color]>* td,
.wp-block-table table[style*=border-top-color]>* th {
  border-top-color: inherit
}

.wp-block-table table[style*=border-top-color] tr:not(:first-child) {
  border-top-color: currentColor
}

.wp-block-table table[style*=border-right-color] td:last-child,
.wp-block-table table[style*=border-right-color] th,
.wp-block-table table[style*=border-right-color] tr,
.wp-block-table table[style*=border-right-color]>* {
  border-right-color: inherit
}

.wp-block-table table[style*=border-bottom-color] tr:last-child,
.wp-block-table table[style*=border-bottom-color] tr:last-child td,
.wp-block-table table[style*=border-bottom-color] tr:last-child th,
.wp-block-table table[style*=border-bottom-color]>*,
.wp-block-table table[style*=border-bottom-color]>* td,
.wp-block-table table[style*=border-bottom-color]>* th {
  border-bottom-color: inherit
}

.wp-block-table table[style*=border-bottom-color] tr:not(:last-child) {
  border-bottom-color: currentColor
}

.wp-block-table table[style*=border-left-color] td:first-child,
.wp-block-table table[style*=border-left-color] th,
.wp-block-table table[style*=border-left-color] tr,
.wp-block-table table[style*=border-left-color]>* {
  border-left-color: inherit
}

.wp-block-table table[style*=border-style] td,
.wp-block-table table[style*=border-style] th,
.wp-block-table table[style*=border-style] tr,
.wp-block-table table[style*=border-style]>* {
  border-style: inherit
}

.wp-block-table table[style*=border-width] td,
.wp-block-table table[style*=border-width] th,
.wp-block-table table[style*=border-width] tr,
.wp-block-table table[style*=border-width]>* {
  border-style: inherit;
  border-width: inherit
}

:where(.wp-block-term-description) {
  margin-bottom: var(--wp--style--block-gap);
  margin-top: var(--wp--style--block-gap)
}

.wp-block-term-description p {
  margin-bottom: 0;
  margin-top: 0
}

.wp-block-text-columns,
.wp-block-text-columns.aligncenter {
  display: flex
}

.wp-block-text-columns .wp-block-column {
  margin: 0 1em;
  padding: 0
}

.wp-block-text-columns .wp-block-column:first-child {
  margin-left: 0
}

.wp-block-text-columns .wp-block-column:last-child {
  margin-right: 0
}

.wp-block-text-columns.columns-2 .wp-block-column {
  width: 50%
}

.wp-block-text-columns.columns-3 .wp-block-column {
  width: 33.33333%
}

.wp-block-text-columns.columns-4 .wp-block-column {
  width: 25%
}

pre.wp-block-verse {
  overflow: auto;
  white-space: pre-wrap
}

:where(pre.wp-block-verse) {
  font-family: inherit
}

.wp-block-video {
  box-sizing: border-box
}

.wp-block-video video {
  width: 100%
}

@supports (position:sticky) {
  .wp-block-video [poster] {
    object-fit: cover
  }
}

.wp-block-video.aligncenter {
  text-align: center
}

.wp-block-video figcaption {
  margin-bottom: 1em;
  margin-top: .5em
}

.editor-styles-wrapper,
.entry-content {
  counter-reset: footnotes
}

a[data-fn].fn {
  counter-increment: footnotes;
  display: inline-flex;
  font-size: smaller;
  text-decoration: none;
  text-indent: -9999999px;
  vertical-align: super
}

a[data-fn].fn:after {
  content: "[" counter(footnotes) "]";
  float: left;
  text-indent: 0
}

.wp-element-button {
  cursor: pointer
}

:root {
  --wp--preset--font-size--normal: 16px;
  --wp--preset--font-size--huge: 42px
}

:root .has-very-light-gray-background-color {
  background-color: #eee
}

:root .has-very-dark-gray-background-color {
  background-color: #313131
}

:root .has-very-light-gray-color {
  color: #eee
}

:root .has-very-dark-gray-color {
  color: #313131
}

:root .has-vivid-green-cyan-to-vivid-cyan-blue-gradient-background {
  background: linear-gradient(135deg, #00d084, #0693e3)
}

:root .has-purple-crush-gradient-background {
  background: linear-gradient(135deg, #34e2e4, #4721fb 50%, #ab1dfe)
}

:root .has-hazy-dawn-gradient-background {
  background: linear-gradient(135deg, #faaca8, #dad0ec)
}

:root .has-subdued-olive-gradient-background {
  background: linear-gradient(135deg, #fafae1, #67a671)
}

:root .has-atomic-cream-gradient-background {
  background: linear-gradient(135deg, #fdd79a, #004a59)
}

:root .has-nightshade-gradient-background {
  background: linear-gradient(135deg, #330968, #31cdcf)
}

:root .has-midnight-gradient-background {
  background: linear-gradient(135deg, #020381, #2874fc)
}

.has-regular-font-size {
  font-size: 1em
}

.has-larger-font-size {
  font-size: 2.625em
}

.has-normal-font-size {
  font-size: var(--wp--preset--font-size--normal)
}

.has-huge-font-size {
  font-size: var(--wp--preset--font-size--huge)
}

.has-text-align-center {
  text-align: center
}

.has-text-align-left {
  text-align: left
}

.has-text-align-right {
  text-align: right
}

#end-resizable-editor-section {
  display: none
}

.aligncenter {
  clear: both
}

.items-justified-left {
  justify-content: flex-start
}

.items-justified-center {
  justify-content: center
}

.items-justified-right {
  justify-content: flex-end
}

.items-justified-space-between {
  justify-content: space-between
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal !important;
  border: 0;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px
}

.screen-reader-text:focus {
  clip: auto !important;
  background-color: #ddd;
  -webkit-clip-path: none;
  clip-path: none;
  color: #444;
  display: block;
  font-size: 1em;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000
}

html :where(.has-border-color) {
  border-style: solid
}

html :where([style*=border-top-color]) {
  border-top-style: solid
}

html :where([style*=border-right-color]) {
  border-right-style: solid
}

html :where([style*=border-bottom-color]) {
  border-bottom-style: solid
}

html :where([style*=border-left-color]) {
  border-left-style: solid
}

html :where([style*=border-width]) {
  border-style: solid
}

html :where([style*=border-top-width]) {
  border-top-style: solid
}

html :where([style*=border-right-width]) {
  border-right-style: solid
}

html :where([style*=border-bottom-width]) {
  border-bottom-style: solid
}

html :where([style*=border-left-width]) {
  border-left-style: solid
}

html :where(img[class*=wp-image-]) {
  height: auto;
  max-width: 100%
}

:where(figure) {
  margin: 0 0 1em
}

html :where(.is-position-sticky) {
  --wp-admin--admin-bar--position-offset: var(--wp-admin--admin-bar--height, 0px)
}

@media screen and (max-width:600px) {
  html :where(.is-position-sticky) {
    --wp-admin--admin-bar--position-offset: 0px
  }
}